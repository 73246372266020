import { Clerk } from "@clerk/clerk-js"
import { jwtDecode } from "jwt-decode"

const clerk = new Clerk(import.meta.env.VITE_CLERK_PUBLISHABLE_KEY as string)

const ACTIVE_STATUSES = ["active", "trialing", "past_due"]
const INACTIVE_STATUSES = [
   "incomplete",
   "incomplete_expired",
   "canceled",
   "unpaid",
   "paused",
]

type UserPublicMetadata = {
   isEzbotEmployee?: boolean
}

type OrgPublicMetaData = {
   subscriptionId: string
   subscriptionStatus: string
}

type UserOrgs = Record<string, string>

type EzbotAppJWTPayload = {
   // Default JWT values plus
   user_metadata: UserPublicMetadata
   user_orgs: UserOrgs
   user_email: string
   user_id: string
   user_full_name: string
   org_metadata: OrgPublicMetaData
   org_name: string
   org_id: string
   org_role: string
   org_slug: string
   org_permissions: string[]
}

const getOrgMemberships = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()
   if (!token) {
      return {}
   }

   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload
   const orgMemberships = decoded.user_orgs

   if (!orgMemberships) {
      return {}
   }

   return orgMemberships
}

const hasActiveStripeSubscription = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()

   if (!token) {
      return false
   }
   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const opm = decoded.org_metadata

   if (!opm) {
      return false
   }

   return ACTIVE_STATUSES.includes(opm.subscriptionStatus)
}

const hasActiveOrg = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()

   if (!token) {
      return false
   }
   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const orgId = decoded.org_id

   if (!orgId) {
      return false
   }

   return true
}

const hasActiveSession = async () => {
   await clerk.load()

   return !!clerk.session
}

const getOrgId = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()
   if (!token) {
      return undefined
   }

   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const orgId = decoded.org_id

   return orgId
}

const getOrgName = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()
   if (!token) {
      return undefined
   }

   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const orgName = decoded.org_name

   return orgName
}

const getOrgSlug = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()

   if (!token) {
      return undefined
   }
   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const orgSlug = decoded.org_slug

   return orgSlug
}

type BasicOrg = {
   id: string
   name: string
   slug: string
   publicMetadata: OrgPublicMetaData
   role: string
   permissions: string[]
}

const getOrg = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()
   if (!token) {
      return undefined
   }

   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const org = {
      id: decoded.org_id,
      name: decoded.org_name,
      slug: decoded.org_slug,
      publicMetadata: decoded.org_metadata,
      role: decoded.org_role,
      permissions: decoded.org_permissions,
   } as BasicOrg

   return org
}

const getUser = async () => {
   await clerk.load()

   // fetches from cache if token present
   const token = await clerk.session?.getToken()
   if (!token) {
      return undefined
   }

   const decoded = jwtDecode(token as string) as EzbotAppJWTPayload

   const user = {
      id: decoded.user_id,
      email: decoded.user_email,
      fullName: decoded.user_full_name,
      publicMetadata: decoded.user_metadata,
      orgs: decoded.user_orgs,
   }

   return user
}

const hasOneOrg = async () => {
   await clerk.load()

   const orgMemberships = await getOrgMemberships()

   if (!orgMemberships) {
      return false
   }

   return Object.keys(orgMemberships).length == 1
}

const setOrgId = async (orgId: string) => {
   await clerk.load()

   clerk.setActive({ organization: orgId })
}

const getNumberOfOrgs = async () => {
   await clerk.load()

   const orgMemberships = await getOrgMemberships()

   if (!orgMemberships) {
      return 0
   }

   return Object.keys(orgMemberships).length
}

const fetchOrgByOrgSlug = async (orgSlug: string) => {
   await clerk.load()

   const orgs = clerk.user?.organizationMemberships

   if (!orgs) {
      return undefined
   }

   const orgMatchFromSlug = orgs.find((m) => m.organization.slug === orgSlug)

   return orgMatchFromSlug
}

const setOnlyOrgAsActive = async () => {
   const memberships = await getOrgMemberships()
   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      throw new Error("No orgs found")
   }

   if (numOrgs === 1) {
      const membershipKeys = Object.keys(memberships)

      await setOrgId(memberships[membershipKeys[0]])
   }
}

const setFirstOrgAsActive = async () => {
   const memberships = await getOrgMemberships()
   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      throw new Error("No orgs found")
   }

   const membershipKeys = Object.keys(memberships)

   await setOrgId(memberships[membershipKeys[0]])
}

const refreshToken = async () => {
   await clerk.load()
   await clerk.session?.getToken()
}

export {
   clerk,
   ACTIVE_STATUSES,
   INACTIVE_STATUSES,
   UserPublicMetadata,
   OrgPublicMetaData,
   EzbotAppJWTPayload,
   BasicOrg,
   hasActiveStripeSubscription,
   hasActiveOrg,
   hasActiveSession,
   setFirstOrgAsActive,
   getOrgMemberships,
   getOrgId,
   hasOneOrg,
   setOrgId,
   getOrgName,
   getOrgSlug,
   getOrg,
   getNumberOfOrgs,
   fetchOrgByOrgSlug,
   setOnlyOrgAsActive,
   getUser,
   refreshToken,
}
