import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes"
import { captureException, setSentryContext } from "@/services/sentry"
import { loadLayoutMiddleware } from "@/router/middleware/loadLayout"
import { useGtm } from "@gtm-support/vue-gtm"

const router = createRouter({
   history: createWebHistory(),
   routes: routes,
})

router.beforeEach(() => {
   setSentryContext()
})

router.beforeEach(loadLayoutMiddleware)

router.afterEach((to) => {
   try {
      const gtm = useGtm()
      gtm?.trackView(String(to.name), to.fullPath)
   } catch (e) {
      captureException(e)
   }
})

export default router
