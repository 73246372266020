<template>
   <div class="variation-editor">
      <div id="variation-container" class="text-left">
         <h5 class="text-left">Visibility Variations</h5>
         <ul id="allowed-values-instructions" class="text-left">
            <li>Default values are indicated by a check mark.</li>
            <li>Click on a value to set it as the default.</li>
         </ul>
         <q-btn
            v-if="showDelete"
            round
            id="delete-button"
            clickable
            push
            @click="visualEditorStore.deleteActiveVariable"
            color="red-13"
            size="sm"
         >
            <q-icon name="mdi-delete" size="xs" color="white" />
         </q-btn>
         <div id="chip-container">
            <template
               v-for="vc in visualEditorStore.allValueChoices"
               v-bind:key="vc"
            >
               <q-chip
                  removable
                  clickable
                  v-model:selected="vc.default"
                  @click="
                     visualEditorStore.setDefaultOnActiveVariable(vc.value)
                  "
                  color="purple-5"
                  size="md"
                  text-color="white"
                  :label="vc.value"
               >
               </q-chip>
            </template>
         </div>
         <div id="add-allowed-value">
            <QBtn
               class="bg-purple-5 text-white save-button"
               push
               label="save"
               @click="visualEditorStore.saveActiveVariable"
            ></QBtn>
         </div>
      </div>
   </div>
</template>
<script setup lang="ts">
import { QBtn, QIcon } from "quasar"
import { QChip } from "quasar"
import { computed } from "vue"
import { useVisualEditorStore } from "../../../../stores/visualEditorStore"
const visualEditorStore = useVisualEditorStore()

const showDelete = computed(() => {
   return visualEditorStore.activeVariable?.id ? true : false
})
</script>
<style lang="scss" scoped>
.variation-editor {
   background-color: purple;
   color: white;
   padding: 10px;
   width: 100%;
   min-height: 200px;
   margin-top: 15px;
}
h5 {
   margin-top: 0;
   margin-bottom: 10px;
}
#chip-container {
   display: flex;
   flex-wrap: wrap;
   gap: 5px;
   min-height: 100px;
   width: 100%;
   outline: 1px dotted $purple-2;
   margin: 10px 0;
   padding: 5px;
   &.error {
      outline: 1px dotted $pink-1;
   }
}
.save-button {
   width: 100%;
}
#allowed-values-instructions {
   padding-inline-start: 15px;
}
#variation-container {
   position: relative;
}
#delete-button {
   position: absolute;
   top: 5px;
   right: 5px;
}
</style>
