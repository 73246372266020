<template>
   <QDialog v-model="dialogOpen" persistent>
      <QCard>
         <QCardSection class="text-center">
            <h1 id="headline">Unauthorized</h1>
            <p>You do not have the permissions to view that page.</p>
            <QBtn color="primary" label="Back" @click="handleBack" />
         </QCardSection>
      </QCard>
   </QDialog>
</template>
<script setup lang="ts">
import { ref } from "vue"
import { QDialog, QCard } from "quasar"
import { useRouter } from "vue-router"
import { getOrg } from "@/services/clerk"
const dialogOpen = ref(true)
const router = useRouter()

const handleBack = async () => {
   const org = await getOrg()
   if (!org) {
      router.go(-1)
   } else {
      router.push({
         name: "OrgHome",
         params: { orgSlug: org.slug },
      })
   }
}
</script>
<style lang="scss">
#headline {
   color: $primary;
}
</style>
