<template>
   <div class="bg-white">
      <div id="create-variable-form-container">
         <div id="variable-type-selector" v-if="props.mode != 'edit'">
            <QSelect
               v-model="variableType"
               :options="variableOptions"
               label="Variable Type"
               required
            ></QSelect>
         </div>
         <EditBasicVariable
            v-if="variableType?.value === 'basic'"
            ref="basic"
            :variable="props.variable"
            :mode="props.mode"
            @variable-submission="handleSubmit"
            @delete-variable="handleDeleteVariable"
         ></EditBasicVariable>
         <EditVisualVariable
            v-else
            ref="visual"
            :variable="props.variable"
            :mode="props.mode"
            @variable-submission="handleSubmit"
            @delete-variable="handleDeleteVariable"
         ></EditVisualVariable>
      </div>
   </div>
</template>
<script setup lang="ts">
import EditBasicVariable from "./EditBasicVariable.vue"
import EditVisualVariable from "./EditVisualVariable.vue"
import { ref, watch, VNodeRef, Ref } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { EditableVariable } from "../types/variable"
import { AxiosError } from "axios"
import { toast } from "vue3-toastify"
import { useQuasar } from "quasar"
import { collectError } from "../utils"
import { v4 as uuidv4 } from "uuid"
import { useRouter } from "vue-router"
const $q = useQuasar()

const $emit = defineEmits(["type-update", "variable-submission"])
const props = defineProps<{
   mode: "create" | "edit"
   variable: EditableVariable
}>()

const router = useRouter()

type VariableTypeDisplay = {
   label: string
   value: string
}
const projectStore = useProjectStore()
const visual: VNodeRef = ref(null)
const basic: VNodeRef = ref(null)
const variableType: Ref<VariableTypeDisplay | null> = ref(null)
if (props.variable.type == "basic") {
   variableType.value = { label: "Basic", value: "basic" }
} else if (props.variable.type == "visual") {
   variableType.value = { label: "Visual", value: "visual" }
} else {
   variableType.value = projectStore.previousCreateVariableType
}

const variableOptions = ref([
   { label: "Basic", value: "basic" },
   { label: "Visual", value: "visual" },
])
const handleDeleteVariable = async (variableId: number) => {
   try {
      if (!projectStore.projectId) {
         throw new Error("No project ID found")
      }
      $q.dialog({
         title: "Delete Variable",
         message:
            "Your dashboard analytics may not update for 24 hours. Are you sure you want to delete this variable?",
         cancel: true,
      }).onOk(async () => {
         await projectStore.deleteVariable(variableId)
         router
            .push({
               name: "Variables",
               params: { projectId: projectStore.projectId },
            })
            .then(() => {
               toast.success("Variable deleted")
            })
         projectStore.variables = await projectStore.getVariablesByProjectId()
      })
   } catch (error) {
      if (error instanceof AxiosError) {
         const msg = error.response?.data.message
         collectError(error)
         if (msg) {
            toast.error(msg)
            return
         }
      } else {
         throw error
      }
   }
}
// watch for changes in variableType and reset validation
watch(variableType, () => {
   basic.value?.resetValidation()
   visual.value?.resetValidation()
   projectStore.previousCreateVariableType = variableType.value
   $emit("type-update", variableType.value?.value)
})
const handleSubmit = async (variable: EditableVariable) => {
   let key: string = ""
   if (variable.key.length > 0) {
      key = variable.key
   } else {
      key = uuidv4()
   }
   const v = {
      ...variable,
      key,
   }
   $emit("variable-submission", v)
}
</script>
<style lang="scss" scoped>
#variable-type-selector {
   margin-bottom: 20px;
   max-width: 250px;
}
</style>
