import { defineStore } from "pinia"

export const useUserStore = defineStore("userStore", {
   state: () => {
      return {
         userId: null as number | null,
         clerkId: null as string | null,
      }
   },
   persist: true,
})
