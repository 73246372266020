<template>
   <div>
      <div class="constrained bg-grey-2">
         <QLayout view="hHR lpr lfr">
            <!--// Header //-->
            <QHeader bordered class="bg-white">
               <QToolbar>
                  <QToolbarTitle class="text-left row items-center">
                     <div class="row items-center">
                        <OrgSwitcher />
                     </div>
                     <div>
                        <ProjectPicker />
                     </div>
                  </QToolbarTitle>
                  <UserButton />
                  <QBtn
                     class="q-ml-md"
                     color="negative"
                     size="sm"
                     @click="handleLogout"
                     ><QIcon name="mdi-logout"
                  /></QBtn>
               </QToolbar>
            </QHeader>
            <!--// Left drawer //-->
            <QDrawer
               v-if="showDrawer"
               v-model="leftDrawerOpen"
               show-if-above
               :mini="leftDrawerMiniState"
               :width="200"
               :breakpoint="500"
               :mini-width="60"
               overlay
               @before-hide="leftDrawerOpen = true"
               @mouseover="leftDrawerMiniState = false"
               @mouseout="leftDrawerMiniState = true"
            >
               <QList>
                  <QItem
                     clickable
                     :active="route.name == 'ProjectHome'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-home-outline" />
                     </QItemSection>
                     <QItemSection> Home </QItemSection>
                  </QItem>
                  <QItem
                     clickable
                     :active="route.name == 'DataExplorer'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/data-explorer`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-compass" />
                     </QItemSection>
                     <QItemSection> Data Explorer </QItemSection>
                  </QItem>
                  <QItem
                     clickable
                     :active="route.name == 'Attributes'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/attributes`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-tag-outline" />
                     </QItemSection>
                     <QItemSection> Attributes </QItemSection>
                  </QItem>
                  <QItem
                     clickable
                     :active="route.name == 'Variables'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/variables`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-variable" />
                     </QItemSection>
                     <QItemSection>Variables</QItemSection>
                  </QItem>
                  <QItem
                     clickable
                     :active="route.name == 'Rewards'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/rewards`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-signal" />
                     </QItemSection>
                     <QItemSection>Rewards</QItemSection>
                  </QItem>
                  <QItem
                     clickable
                     :active="route.name == 'VisualEditor'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/visual-editor`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-creation" />
                     </QItemSection>
                     <QItemSection>Visual Editor</QItemSection>
                  </QItem>
                  <!-- <QItem
                     clickable
                     :active="route.name == 'Checkpoints'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/checkpoints`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-flag-checkered" />
                     </QItemSection>
                     <QItemSection> Checkpoints </QItemSection>
                  </QItem>

                  <QItem
                     clickable
                     :active="route.name == 'History'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/history`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-history" />
                     </QItemSection>
                     <QItemSection> History </QItemSection>
                  </QItem>
                  <QItem
                     clickable
                     :active="route.name == 'Debugger'"
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/debugger`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-bug-outline" />
                     </QItemSection>
                     <QItemSection> Debugger </QItemSection>
                  </QItem> -->
               </QList>
               <QList>
                  <QItem
                     clickable
                     :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}/settings`"
                  >
                     <QItemSection avatar>
                        <QIcon name="mdi-cog-outline" />
                     </QItemSection>
                     <QItemSection> Project Settings </QItemSection>
                  </QItem>
                  <QSeparator />
                  <QItem clickable href="https://docs.ezbot.ai" target="_blank">
                     <QItemSection avatar>
                        <QIcon name="mdi-book-outline" />
                     </QItemSection>
                     <QItemSection>Docs</QItemSection>
                  </QItem>
               </QList>
            </QDrawer>
            <!--// Main content //-->
            <QPageContainer class="bg-grey-2">
               <Suspense>
                  <PageLayout>
                     <template #page>
                        <slot />
                     </template>
                  </PageLayout>
               </Suspense>
            </QPageContainer>
         </QLayout>
      </div>
      <HelpFab />
   </div>
</template>

<script setup lang="ts">
import { QIcon, QBtn } from "quasar"
import { computed, ref } from "vue"
import { useProjectStore, useUserStore } from "../stores"
import PageLayout from "./PageLayout.vue"
import ProjectPicker from "../components/ProjectPicker.vue"
import { OrgSwitcher, HelpFab } from "../components"
import { useClerk, UserButton } from "vue-clerk"
import router from "@/router"
import { useRoute } from "vue-router"
import { useAppState } from "../stores"
import { storeToRefs } from "pinia"
import { getOrg } from "@/services/clerk"

const clerk = useClerk()
const route = useRoute()
const appStore = useAppState()

const leftDrawerMiniState = ref(true)

const { leftDrawerOpen } = storeToRefs(appStore)

const projectStore = useProjectStore()
const userStore = useUserStore()

const org = await getOrg()
if (!org) {
   throw new Error("Org is undefined")
}
const { slug } = org
const urlSlug = ref(slug)

const showDrawer = computed(() => {
   return projectStore.projectId && slug
})

const handleLogout = async () => {
   await clerk.signOut(() => {
      router.push({ name: "SignedOut" })
      projectStore.$reset()
      userStore.$reset()
   })
}
</script>

<style type="text/css">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

.constrained {
   width: 100%;
   height: 100%;
}

.constrained .q-layout,
.constrained .q-header,
.constrained .q-footer {
   margin: 0 auto;
}

.nav-btn {
   min-width: 90px;
}
.page {
   margin-left: 60px;
}
</style>
