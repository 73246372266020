<template>
   <div class="bg-white q-pa-md">
      <h1 class="text-left text-h4">{{ humanReadableName }}</h1>
      <div class="chip-container">
         <QChip id="attribute-id-chip">Attribute Id: {{ id }}</QChip>
      </div>
      <QForm id="attribute-edit-form" @submit="handleSubmit">
         <q-input
            v-model="humanReadableName"
            filled
            label="Human Readable Name"
            lazy-rules
            :rules="[
               (val) => (val && val.length > 0) || 'Please type something',
            ]"
         />
         <q-input
            v-model="key"
            filled
            label="Attribute Key"
            lazy-rules
            :rules="[
               (val) => (val && val.length > 0) || 'Please type something',
            ]"
         />
         <div class="row justify-between">
            <QBtn color="negative" @click="openDeleteDialog"
               ><QIcon name="mdi-delete"
            /></QBtn>
            <QBtn
               id="submit"
               type="submit"
               color="primary"
               :disable="showDisabledSubmit"
               >Submit</QBtn
            >
         </div>
      </QForm>
   </div>
</template>
<script setup lang="ts">
import { QForm, QInput, QChip, QBtn, useQuasar } from "quasar"
import { ref, computed } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { useRoute, useRouter } from "vue-router"
import { toast } from "vue3-toastify"

const $q = useQuasar()
const projectStore = useProjectStore()
const route = useRoute()
const router = useRouter()
const attributeIdParam = route.params.attributeId
let attributeId: number
if (typeof attributeIdParam === "string") {
   attributeId = parseInt(attributeIdParam)
} else {
   throw new Error("Error getting attribute id from route params")
}
const localAttribute = await projectStore.getAttribute(attributeId)
const id = ref(localAttribute.id)
const humanReadableName = ref(localAttribute.humanReadableName)
const key = ref(localAttribute.key)

const openDeleteDialog = () => {
   $q.dialog({
      title: "Delete Attribute",
      message: "Are you sure you want to delete this attribute?",
      cancel: true,
   }).onOk(async () => {
      await projectStore.deleteAttribute(id.value)
      router.push({ name: "Attributes" }).then(() => {
         toast.success("Attribute deleted")
      })
   })
}

const handleSubmit = async () => {
   await projectStore.updateAttribute(id.value, {
      humanReadableName: humanReadableName.value,
      projectId: localAttribute.projectId,
      key: key.value,
   })
   router.push({ name: "Attributes" }).then(() => {
      toast.success("Attribute updated")
   })
}

const showDisabledSubmit = computed(() => {
   return localAttribute.humanReadableName === humanReadableName.value &&
      localAttribute.key === key.value
      ? true
      : false
})
</script>
<style lang="scss">
h3 {
   margin: 0;
}
.chip-container {
   display: flex;
   align-items: left;
   margin-bottom: 1em;
}
#attribute-id-chip {
   margin-left: 0;
}
#created-by-chip {
   margin-left: 0;
}
#attribute-edit-form {
   border: 1px dotted darkgrey;
   padding: 1em;
   margin-bottom: 1em;
}
#attribute-edit-form {
   width: 100%;
   max-width: 600px;
}
.q-field {
   margin-bottom: 10px;
}
</style>
