import { ChartDataset, Point } from "chart.js"
import { useDashboardStore } from "@/stores"

function createOptimizedVsDefaultDatasets(
   showConfidenceIntervals: boolean = false,
): ChartDataset<"line", (number | Point | null)[]>[] | undefined {
   const dashboardStore = useDashboardStore()
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
   if (showConfidenceIntervals) {
      const ub =
         dashboardStore.time_series?.ezbot_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.ezbot_std_dev?.[index] || 1
               return ezbot + 2.576 * stdDev
            },
         ) ?? []
      datasets.push({
         label: "Optimized UB",
         data:
            ub.map((x) => {
               const y = x * 100
               return parseFloat(y.toFixed(2))
            }) ?? [],
         backgroundColor: "rgba(128,0,247,0.3)",
         fill: "+1",
         borderColor: "rgba(128,0,247,0.3)",
         pointRadius: 0,
         showLine: false,
      })
   }
   datasets.push({
      label: "Optimized Combos",
      data:
         dashboardStore.time_series?.ezbot_conversion_rate!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#7E00F7",
      borderColor: "#7E00F7",
      pointRadius: 0,
   })
   if (showConfidenceIntervals) {
      const lb =
         dashboardStore.time_series?.ezbot_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.ezbot_std_dev?.[index] || 1
               return ezbot - 2.576 * stdDev
            },
         ) ?? []
      datasets.push({
         label: "Optimized LB",
         data:
            lb.map((x) => {
               const y = x * 100
               return parseFloat(y.toFixed(2))
            }) ?? [],
         backgroundColor: "rgba(128,0,247,0.3)",
         borderColor: "rgba(128,0,247,0.3)",
         fill: "-1",
         pointRadius: 0,
         showLine: false,
      })
      const ub =
         dashboardStore.time_series?.default_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.default_std_dev?.[index] || 1
               return ezbot + 2.576 * stdDev
            },
         ) ?? []
      datasets.push({
         label: "Default UB",
         data: ub.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         backgroundColor: "rgba(110,110,110,0.1)",
         fill: "+1",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }

   datasets.push({
      label: "Default",
      data:
         dashboardStore.time_series?.default_conversion_rate!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#6e6e6e",
      borderColor: "#6e6e6e",
      pointRadius: 0,
   })
   if (showConfidenceIntervals) {
      const lb =
         dashboardStore.time_series?.default_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.default_std_dev?.[index] || 1
               return ezbot - 2.576 * stdDev
            },
         ) ?? []
      datasets.push({
         label: "Default LB",
         data: lb.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         fill: "-1",
         backgroundColor: "rgba(110,110,110,0.1)",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }
   return datasets
}

function createOptimizedVsDefaultRelativeDatasets(
   showConfidenceIntervals: boolean = false,
): ChartDataset<"line", (number | Point | null)[]>[] | undefined {
   const dashboardStore = useDashboardStore()
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []

   const relativePerformance =
      dashboardStore.time_series?.ezbot_conversion_rate?.map((ezbot, index) => {
         const defaultRate =
            dashboardStore.time_series?.default_conversion_rate?.[index] || 1
         return (ezbot / defaultRate - 1) * 100
      }) ?? []

   if (showConfidenceIntervals) {
      const ub =
         dashboardStore.time_series?.ezbot_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.ezbot_std_dev?.[index] || 1
               const defaultRate =
                  dashboardStore.time_series?.default_conversion_rate?.[
                     index
                  ] || 1
               const ezb = ezbot + 2.576 * stdDev
               return (ezb / defaultRate - 1) * 100
            },
         ) ?? []
      datasets.push({
         label: "Optimized UB",
         data:
            ub.map((x) => {
               const y = x * 100
               return parseFloat(y.toFixed(2))
            }) ?? [],
         backgroundColor: "rgba(128,0,247,0.3)",
         fill: "+1",
         borderColor: "rgba(128,0,247,0.3)",
         pointRadius: 0,
         showLine: false,
      })
   }
   datasets.push({
      label: "Relative Performance",
      data: relativePerformance.map((x) => parseFloat(x.toFixed(2))),
      backgroundColor: "#7E00F7",
      borderColor: "#7E00F7",
      pointRadius: 0,
   })
   if (showConfidenceIntervals) {
      const lb =
         dashboardStore.time_series?.ezbot_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.ezbot_std_dev?.[index] || 1
               const defaultRate =
                  dashboardStore.time_series?.default_conversion_rate?.[
                     index
                  ] || 1
               const ezb = ezbot - 2.576 * stdDev
               return (ezb / defaultRate - 1) * 100
            },
         ) ?? []
      datasets.push({
         label: "Optimized LB",
         data:
            lb.map((x) => {
               const y = x * 100
               return parseFloat(y.toFixed(2))
            }) ?? [],
         backgroundColor: "rgba(128,0,247,0.3)",
         borderColor: "rgba(128,0,247,0.3)",
         fill: "-1",
         pointRadius: 0,
         showLine: false,
      })
      const ub =
         dashboardStore.time_series?.default_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.default_std_dev?.[index] || 1
               const ezb = ezbot + 2.576 * stdDev
               return (ezb / ezbot - 1) * 100
            },
         ) ?? []
      datasets.push({
         label: "Default UB",
         data: ub.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         backgroundColor: "rgba(110,110,110,0.1)",
         fill: "+1",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }
   datasets.push({
      label: "Default",
      data:
         dashboardStore.time_series?.default_conversion_rate!.map(() => {
            const y = 0
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#6e6e6e",
      borderColor: "#6e6e6e",
      pointRadius: 0,
   })
   if (showConfidenceIntervals) {
      const lb =
         dashboardStore.time_series?.default_conversion_rate?.map(
            (ezbot, index) => {
               const stdDev =
                  dashboardStore.time_series?.default_std_dev?.[index] || 1
               const ezb = ezbot - 2.576 * stdDev
               return (ezb / ezbot - 1) * 100
            },
         ) ?? []
      datasets.push({
         label: "Default LB",
         data: lb.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         fill: "-1",
         backgroundColor: "rgba(110,110,110,0.1)",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }
   return datasets
}

function createNTileVsDefaultDatasets():
   | ChartDataset<"line", (number | Point | null)[]>[]
   | undefined {
   const dashboardStore = useDashboardStore()
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
   datasets.push({
      label: "Top 50th Percentile",
      data:
         dashboardStore.time_series?.ntile_conversion_rate!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#7E00F7",
      borderColor: "#7E00F7",
      pointRadius: 0,
   })
   datasets.push({
      label: "Default",
      data:
         dashboardStore.time_series?.default_conversion_rate!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#6e6e6e",
      borderColor: "#6e6e6e",
      pointRadius: 0,
   })
   return datasets
}

function createNTileVsDefaultRelativeDatasets():
   | ChartDataset<"line", (number | Point | null)[]>[]
   | undefined {
   const dashboardStore = useDashboardStore()
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []

   const relativePerformance =
      dashboardStore.time_series?.ntile_conversion_rate?.map((ezbot, index) => {
         const defaultRate =
            dashboardStore.time_series?.default_conversion_rate?.[index] || 1
         return (ezbot / defaultRate - 1) * 100
      }) ?? []

   datasets.push({
      label: "Relative Top 50% Percentile Performance",
      data: relativePerformance.map((x) => parseFloat(x.toFixed(2))),
      backgroundColor: "#7E00F7",
      borderColor: "#7E00F7",
      pointRadius: 0,
   })
   datasets.push({
      label: "Default",
      data:
         dashboardStore.time_series?.default_conversion_rate!.map(() => {
            const y = 0
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#6e6e6e",
      borderColor: "#6e6e6e",
      pointRadius: 0,
   })
   return datasets
}

function createNTileServingRateDatasets():
   | ChartDataset<"line", (number | Point | null)[]>[]
   | undefined {
   const dashboardStore = useDashboardStore()
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
   datasets.push({
      label: "Top 50th Percentile",
      data:
         dashboardStore.time_series?.ntile_serving_rate!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "rgba(126, 0, 247, 0.3)",
      borderColor: "#7E00F7",
      fill: true,
      pointRadius: 0,
   })
   datasets.push({
      label: "Null Hypothesis",
      data:
         dashboardStore.time_series?.ntile_serving_rate!.map(() => {
            const y = 50
            return parseFloat(y.toFixed(2))
         }) ?? [],
      backgroundColor: "#42f2f5",
      borderColor: "#42f2f5",
      pointRadius: 0,
   })
   return datasets
}

export {
   createOptimizedVsDefaultDatasets,
   createOptimizedVsDefaultRelativeDatasets,
   createNTileVsDefaultDatasets,
   createNTileVsDefaultRelativeDatasets,
   createNTileServingRateDatasets,
}
