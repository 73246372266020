<template>
   <span
      :class="{ 'truncated-renderer': true, 'value-truncated': valueTruncated }"
   >
      {{ truncatedVal }}
      <QTooltip
         v-if="valueTruncated"
         class="bg-primary text-body2"
         max-width="300px"
      >
         {{ props.val }}
      </QTooltip>
   </span>
</template>
<script setup lang="ts">
import { computed } from "vue"

const props = defineProps({
   val: {
      type: String,
      required: true,
   },
})
const MAX_LENGTH = 50
const truncatedVal = computed(() => {
   const val = props.val
   if (typeof val === "string") {
      return val.length > MAX_LENGTH ? val.slice(0, MAX_LENGTH) + "..." : val
   } else {
      return val
   }
})
const valueTruncated = computed(() => {
   return String(props.val).length > MAX_LENGTH
})
</script>
<style lang="scss" scoped>
.truncated-renderer {
   text-wrap: wrap;
   text-align: left;
   display: inline;
}
.value-truncated {
   cursor: help;
}
</style>
