<template>
   <QPage id="page" class="page" padding :style-fn="tweakMinHeight">
      <Suspense>
         <slot name="page"></slot>
      </Suspense>
   </QPage>
</template>
<script setup lang="ts">
import { QPage } from "quasar"
const tweakMinHeight = () => ({ minHeight: "none" })
</script>
<style lang="scss"></style>
