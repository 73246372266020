import {
   captureException,
   setSentryContext,
   captureMessage,
} from "../services/sentry"

const collectMessage = async (message: string): Promise<void> => {
   await setSentryContext()
   captureMessage(message)
}

const collectError = async (error: Error): Promise<void> => {
   await setSentryContext()
   captureException(error)
}

export { collectError, captureMessage, collectMessage }
