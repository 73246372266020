<template>
   <div id="subscription-container">
      <h2 class="text-h4">Subscription</h2>
      <div v-if="customerPortalLink">
         <a :href="customerPortalLink" target="_blank"
            >Manage your subscription here.</a
         >
      </div>
      <div v-else>
         <p>Something went wrong. Please contact support@ezbot.ai</p>
      </div>
   </div>
</template>
<script setup lang="ts">
import { Ref, ref } from "vue"
const customerPortalLink: Ref<string | undefined> = ref(undefined)
const url = import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL_URL
if (url) {
   customerPortalLink.value = String(url)
} else {
   console.error("Stripe customer portal URL not found.")
}
</script>
<style lang="scss" scoped>
#subscription-container {
   text-align: left;
}
</style>
