<template>
   <Suspense>
      <slot />
   </Suspense>
</template>

<script>
export default {
   name: "AppLayoutDefault",
}
</script>
