<template>
   <QDialog v-model="dialogOpen" persistent>
      <QCard class="q-pa-lg">
         <div class="text-center">
            <QIcon name="mdi-check-circle" size="12em" color="primary" />
         </div>
         <QCardSection class="text-center">
            <div class="text-h4">Thanks for using ezbot.ai</div>
            <div class="text-subtitle2">You have been logged out.</div>
            <RouterLink id="login-link" to="/sign-in">Log back in</RouterLink>
         </QCardSection>
      </QCard>
   </QDialog>
</template>
<script setup lang="ts">
import { ref } from "vue"
import { QDialog, QCard } from "quasar"

const dialogOpen = ref(true)
// Log out, assuming localStorage is used for authentication
localStorage.clear()
</script>
<style lang="scss">
#headline {
   color: $primary;
}
#login-link {
   display: block;
   margin-top: 1em;
   text-decoration: none;
   color: $primary;
   font-weight: bold;
}
</style>
