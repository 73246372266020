<template>
   <span>
      {{ number }}
   </span>
</template>
<script setup lang="ts">
import { computed, PropType } from "vue"

const props = defineProps({
   val: {
      type: Number as PropType<string | number>,
      required: true,
   },
})
const number = computed(() => {
   if (!props.val) {
      return "0"
   } else {
      return props.val
   }
})
</script>
