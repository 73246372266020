<template>
   <div id="fab-container">
      <QFab
         position="bottom-right"
         icon="mdi-help"
         direction="up"
         color="info"
         vertical-actions-align="right"
      >
         <QFabAction
            color="primary"
            @click="onFabDocsClick"
            icon="mdi-book-outline"
            label="Docs"
            external-label
            label-position="left"
         />
         <QFabAction
            color="secondary"
            @click="onFabMailClick"
            icon="mdi-mail"
            label="support@ezbot.ai"
            external-label
            label-position="left"
         />
      </QFab>
   </div>
</template>
<script setup lang="ts">
import { QFab, QFabAction } from "quasar"
const onFabDocsClick = () => {
   window.open("https://docs.ezbot.ai", "_blank")
}
const onFabMailClick = () => {
   window.open("mailto:support@ezbot.ai", "_blank")
}
</script>
<style scoped lang="scss">
#fab-container {
   position: fixed;
   bottom: 20px;
   right: 20px;
   a {
      margin-right: 0;
   }
}
</style>
