<template>
   <div>
      <QTable
         :rows="fakeData"
         :columns="columns"
         row-key="id"
         title="Model Checkpoints"
      >
         <template v-slot:body>
            <tr>
               <td v-for="column in columns" :key="column.name">
                  <QSkeleton />
               </td>
            </tr>
         </template>
      </QTable>
   </div>
</template>

<script setup lang="ts">
import { reactive } from "vue"
import { QTable, QSkeleton, QTableColumn } from "quasar"

const columns: QTableColumn[] = [
   { name: "model", label: "Model", align: "left", field: "model" },
   {
      name: "checkpoint-id",
      label: "Checkpoint Id",
      align: "left",
      field: "checkpoint-id",
   },
   { name: "column", label: "When", align: "left", field: "time-ago" },
]
const fakeData = reactive([
   {
      id: 1,
      column1: "Data 1",
      column2: "Data 2",
      column3: "Data 3",
   },
   {
      id: 2,
      column1: "Data 4",
      column2: "Data 5",
      column3: "Data 6",
   },
   {
      id: 3,
      column1: "Data 7",
      column2: "Data 8",
      column3: "Data 9",
   },
   {
      id: 4,
      column1: "Data 1",
      column2: "Data 2",
      column3: "Data 3",
   },
   {
      id: 5,
      column1: "Data 4",
      column2: "Data 5",
      column3: "Data 6",
   },
   {
      id: 6,
      column1: "Data 7",
      column2: "Data 8",
      column3: "Data 9",
   },
])
</script>
