<template>
   <QCard class="graph-card">
      <QCardSection class="bg-primary">
         <div class="text-h5 text-white">Performance</div>
      </QCardSection>
      <QCardSection>
         <div id="controls">
            <QToggle
               size="sm"
               v-model="showOptimizedVsDefaultConfidenceIntervals"
               :val="showOptimizedVsDefaultConfidenceIntervals"
               label="Show 99% Confidence Intervals"
            />
         </div>
         <OptimizedVsDefaultGraphV2
            :show-confidence-intervals="
               showOptimizedVsDefaultConfidenceIntervals
            "
         />
         <NTileTrafficDistributionGraph />
         <NTileVsDefaultConversionRateGraph
            id="conversion-graph"
            class="graph"
            :showRelative="showRelative"
         ></NTileVsDefaultConversionRateGraph>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { ref } from "vue"
import OptimizedVsDefaultGraphV2 from "../../OptimizedVsDefaultGraphV2.vue"
import NTileTrafficDistributionGraph from "../../NTileTrafficDistributionGraph.vue"
import NTileVsDefaultConversionRateGraph from "../../conversion-rate-graph-v2/NTileVsDefaultConversionRateGraph.vue"
const showOptimizedVsDefaultConfidenceIntervals = ref(false)
const showRelative = ref(false)
</script>
<style scoped lang="scss">
#optimized-vs-default-graph-controls {
   display: flex;
   justify-content: start;
   margin-bottom: 10px;
}
#controls {
   display: flex;
   gap: 1em;
}
</style>
