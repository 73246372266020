import {
   getNumberOfOrgs,
   getOrg,
   getOrgSlug,
   hasActiveOrg,
   hasActiveSession,
   hasActiveStripeSubscription,
} from "@/services/clerk"
import { addBreadcrumb } from "../../services/sentry"
import { useProjectStore } from "@stores/projectStore"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"

const requireLogin = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating user is logged in for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   return next()
}

const requireOrgMembership = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating user belongs to org for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   return next()
}

const requireActiveSubscription = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating org has active subscription for route ${to.fullPath}`,
      level: "info",
   })
   const activeOrg = await hasActiveOrg()
   if (!activeOrg) {
      return next({ name: "SelectOrg" })
   }

   const activeSubscription = await hasActiveStripeSubscription()
   if (!activeSubscription) {
      return next({ name: "ActivateSubscription" })
   }

   return next()
}

const requireOrgProjects = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating org has projects for route ${to.fullPath}`,
      level: "info",
   })
   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      return next({ name: "SelectOrg" })
   }

   const projectStore = useProjectStore()
   const projects = projectStore.projects

   if (!projects || projects.length === 0) {
      return next({ name: "CreateProject" })
   }

   return next()
}

const projectBelongsToOrg = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating project belongs to org for route ${to.fullPath}`,
      level: "info",
   })
   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      return next({ name: "SelectOrg" })
   }

   const projectStore = useProjectStore()
   const projects = projectStore.projects

   if (!projects || projects.length === 0) {
      return next({ name: "CreateProject" })
   }

   const projectId = to.params.projectId
   if (!projectId) {
      return next({ name: "GenericError" })
   }

   const project = projects.find((p) => p.id === parseInt(String(projectId)))

   if (!project) {
      return next({ name: "Unauthorized" })
   }

   return next()
}

const requireValidProject = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating project for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      return next({ name: "SelectOrg" })
   }

   const projectStore = useProjectStore()

   await projectStore.getActiveOrgProjects()
   const projects = projectStore.projects

   if (!projects || projects.length === 0) {
      const orgSlug = await getOrgSlug()
      if (!orgSlug) {
         throw new Error("Organization slug not found")
      }
      return next({ name: "CreateProject", params: { orgSlug: orgSlug } })
   }

   const projectId = to.params.projectId

   const org = await getOrg()
   if (!org) {
      throw new Error("No active org found")
   }
   const { slug } = org
   if (!slug) {
      throw new Error("Organization slug not found")
   }

   if (!projectId) {
      if (projectStore.projectId) {
         return next({
            name: "ProjectHome",
            params: {
               projectId: projectStore.projectId,
               orgSlug: slug,
            },
         })
      }
      // TODO: Improve UX with a toast error
      return next({
         name: "ProjectHome",
         params: { projectId: projects[0].id, orgSlug: slug },
      })
   }

   const project = projects.find((p) => p.id === parseInt(String(projectId)))

   if (!project) {
      return next({ name: "Unauthorized" })
   }

   projectStore.projectId = project.id
   return next()
}

export {
   requireLogin,
   requireOrgMembership,
   requireActiveSubscription,
   requireOrgProjects,
   projectBelongsToOrg,
   requireValidProject,
}
