<template>
   <div id="onboarding-container" class="q-pa-md">
      <Teleport to="head">
         <Component
            :is="'script'"
            src="https://js.stripe.com/v3/pricing-table.js"
            async
         />
      </Teleport>
      <div id="checkout-container">
         <div id="pricing-table-container">
            <QDialog v-model="dialogOpen" persistent>
               <QCard id="pricing-card" class="q-pa-md">
                  <QCardSection id="card-section">
                     <h1 class="text-h3 text-center">
                        Unlock the full potential of your website.
                     </h1>
                     <div id="stripe-pricing-table-container">
                        <Component
                           :is="'stripe-pricing-table'"
                           :pricing-table-id="`${pricingTableId}`"
                           :publishable-key="`${pk}`"
                           :client-reference-id="`${orgId}`"
                        />
                     </div>
                     <p id="after-text" class="text-center">
                        Not sure how ezbot can help your business grow? Learn
                        about the benefits of optimization in our
                        <a href="https://docs.ezbot.ai" target="_blank">docs</a>
                        or reach out to our team at
                        <a href="mailto:support@ezbot.ai">support@ezbot.ai</a>.
                     </p>
                  </QCardSection>
               </QCard>
            </QDialog>
         </div>
      </div>
   </div>
</template>
<script setup lang="ts">
import { useAuth } from "vue-clerk"
import { onMounted, ref } from "vue"
import { QCard, QCardSection, QDialog } from "quasar"
import { trackEvent } from "@/analytics/trackEvent"
const pricingTableId = String(import.meta.env.VITE_STRIPE_PRICING_TABLE_ID)
const pk = String(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
const { orgId } = useAuth()
const dialogOpen = ref(true)
onMounted(() => {
   trackEvent("viewed_in_app_pricing_table")
})
</script>
<style lang="scss" scoped>
#pricing-table-container {
   width: 90%;
   h1 {
      margin-bottom: 10px;
   }
}
#subtitle {
   text-align: center;
   margin-bottom: 50px;
}
#checkout-container {
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
}
#pricing-card {
   width: 100%;
   max-width: 1200px;
}
#after-text {
   font-style: italic;
}
#stripe-pricing-table-container {
   margin-bottom: 3em;
}
</style>
