<template>
   <QDialog v-model="dialogOpen" persistent>
      <QCard>
         <QCardSection class="text-center">
            <h1 id="headline">Page Not Found</h1>
         </QCardSection>
      </QCard>
   </QDialog>
</template>
<script setup lang="ts">
import { ref } from "vue"
import { QDialog, QCard } from "quasar"
const dialogOpen = ref(true)
</script>
<style lang="scss">
#headline {
   color: $primary;
}
</style>
