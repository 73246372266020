const validateNewFontSizeVariation = (val: string): boolean | string => {
   const regex = /^(\d*\.?\d+)(px|em|rem)$/
   if (regex.test(val)) {
      return true
   }
   return "Must be a valid font size value with a unit of px, em, or rem"
}
const validateNewFontColorVariation = (val: string): boolean | string => {
   // use regex to test for a HEX, rgb, or rgba color value
   const regex =
      /^(#([0-9a-fA-F]{3}){1,2})|(rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\))|(rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0(\.\d+)?|1(\.0+)?)\))$/
   if (regex.test(val)) {
      return true
   }
   return "Must be a valid HEX, RGB, or RGBA color value"
}
const validateNewBackgroundColorVariation = (val: string): boolean | string => {
   // use regex to test for a HEX, rgb, or rgba color value
   const regex =
      /^(#([0-9a-fA-F]{3}){1,2})|(rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\))|(rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0(\.\d+)?|1(\.0+)?)\))$/
   if (regex.test(val)) {
      return true
   }
   return "Must be a valid HEX, RGB, or RGBA color value"
}
const validateNewURLVariation = (val: string): boolean | string => {
   // use regex to test if val starts with http:// or https:// and is a valid url
   const regex = /^(http|https):\/\/[^ "]+$/
   if (regex.test(val)) {
      return true
   }
   return "Must be a valid URL that starts with http:// or https://"
}

export {
   validateNewFontSizeVariation,
   validateNewFontColorVariation,
   validateNewBackgroundColorVariation,
   validateNewURLVariation,
}
