import {
   Combination,
   VariableValue,
   PlaceValueVariable,
   DBVariable,
} from "@/types/variable"

const createReversedPlaceValues = (
   variables: DBVariable[],
): PlaceValueVariable[] => {
   // sort variables alphabetically by key
   const sortedVariables = variables.sort((a, b) => {
      const keyA = a.key
      const keyB = b.key
      if (keyA < keyB) {
         return -1
      }
      if (keyA > keyB) {
         return 1
      }
      return 0
   })

   const variableCardinalities: Array<number> = []
   sortedVariables.forEach((variable) => {
      const constraintLength = variable.constraints.enumerables.length + 1 // for default value
      variableCardinalities.push(constraintLength)
   })
   const reversedPlaceValues: Array<PlaceValueVariable> = []
   reversedPlaceValues.push({ placeValue: 1, variable: sortedVariables[0] })
   let product = 1
   variableCardinalities.forEach((cardinality, i) => {
      product *= cardinality
      reversedPlaceValues.unshift({
         placeValue: product,
         variable: sortedVariables[i + 1],
      })
   })
   reversedPlaceValues.shift()
   return reversedPlaceValues
}

const decodeCombination = (
   reversedPlaceValues: PlaceValueVariable[],
   combination: number,
): Combination => {
   let combinationRemainder = combination
   const variationList: Array<VariableValue> = []
   reversedPlaceValues.forEach((PlaceValueVariable) => {
      const variationIndex = Math.floor(
         Number(combinationRemainder) / PlaceValueVariable.placeValue,
      )
      combinationRemainder =
         combinationRemainder % PlaceValueVariable.placeValue

      if (variationIndex === 0) {
         const defaultValue = PlaceValueVariable.variable.defaultValue
         variationList.push({
            variableId: PlaceValueVariable.variable.id,
            humanReadableName: PlaceValueVariable.variable.humanReadableName,
            value: defaultValue,
         })
      } else {
         const sortedEnumerables =
            PlaceValueVariable.variable.constraints.enumerables.sort()
         const value = sortedEnumerables[variationIndex - 1]
         variationList.push({
            variableId: PlaceValueVariable.variable.id,
            humanReadableName: PlaceValueVariable.variable.humanReadableName,
            value,
         })
      }
   })
   return variationList
}

export { decodeCombination, createReversedPlaceValues }
