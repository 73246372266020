<template>
   <div class="variation-editor">
      <div id="variation-container" class="text-left">
         <div id="allowed-values-container">
            <h5 class="text-left">Allowed Values</h5>
            <ul id="allowed-values-instructions" class="text-left">
               <li>Default values are indicated by a check mark.</li>
               <li>Click on a value to set it as the default.</li>
            </ul>
            <q-btn
               v-if="showDelete"
               round
               id="delete-button"
               clickable
               push
               @click="visualEditorStore.deleteActiveVariable"
               color="red-13"
               size="sm"
            >
               <q-icon name="mdi-delete" size="xs" color="white" />
            </q-btn>
         </div>
         <div id="chips-picker-container">
            <div id="chip-container" ref="chipContainer">
               <template
                  v-for="vc in visualEditorStore.allValueChoices"
                  v-bind:key="vc"
               >
                  <q-chip
                     removable
                     clickable
                     v-model:selected="vc.default"
                     @click="
                        visualEditorStore.setDefaultOnActiveVariable(vc.value)
                     "
                     @remove="
                        visualEditorStore.removeValueFromActiveVariable(
                           vc.value,
                        )
                     "
                     :style="`background-color: ${vc.value}`"
                     text-color="white"
                     class="color-chip"
                  >
                  </q-chip>
               </template>
            </div>
            <q-form @submit="visualEditorStore.saveActiveVariable">
               <div id="gradient-color-picker-container">
                  <q-color
                     id="gradient-color-picker"
                     v-model="newAllowedValue"
                     class="my-picker"
                     dark
                     no-footer
                  />
                  <q-btn
                     id="add-gradient-color"
                     icon-right="mdi-plus-box"
                     label="Add Value"
                     push
                     @click="handleAddValue"
                     :style="`background-color: ${newAllowedValue}`"
                  ></q-btn>
               </div>
               <QBtn
                  id="save-button"
                  class="bg-purple-5 text-white save-button"
                  push
                  label="save"
                  type="submit"
               ></QBtn>
            </q-form>
         </div>
      </div>
   </div>
</template>
<script setup lang="ts">
import { QBtn, QColor, QIcon, QForm } from "quasar"
import { QChip } from "quasar"
import { computed, ref } from "vue"
import { useVisualEditorStore } from "../../../../stores/visualEditorStore"

const visualEditorStore = useVisualEditorStore()
const newAllowedValue = ref("")

const showDelete = computed(() => {
   return visualEditorStore.activeVariable?.id ? true : false
})

const handleAddValue = () => {
   visualEditorStore.addValueToActiveVariable(newAllowedValue.value)
   newAllowedValue.value = ""
}
</script>
<style lang="scss" scoped>
.variation-editor {
   background-color: purple;
   color: white;
   padding: 10px;
   width: 100%;
   min-height: 200px;
   margin-top: 15px;
}
h5 {
   margin-top: 0;
   margin-bottom: 10px;
}
#chip-container {
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   align-items: center;
   gap: 5px;
   min-height: 100px;
   width: 50%;
   outline: 1px dotted $purple-2;
   padding: 5px;
}
#add-allowed-value {
   width: 50%;
}
#allowed-values-instructions {
   padding-inline-start: 15px;
}

.color-picker-input {
   width: 60%;
}
#save-button {
   margin-top: 10px;
   width: 100%;
}
#variation-container {
   position: relative;
}
#delete-button {
   position: absolute;
   top: 5px;
   right: 5px;
}
.color-chip {
   width: 95%;
}
#add-gradient-color {
   width: 100%;
   margin-top: 10px;
}
#chips-picker-container {
   display: flex;
   justify-content: left;
   align-items: top;
   gap: 10px;
}
</style>
