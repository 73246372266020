const formatPercentTwoDecimals = (raw: number) => {
   if (raw === -1) {
      return "None"
   }
   return (raw * 100).toFixed(2) + "%"
}

const formatPercentTwoDecimalsWithSign = (raw: number) => {
   if (raw === -1) {
      return "None"
   }
   const withoutSign = (raw * 100).toFixed(2) + "%"
   if (raw > 0) {
      return "+" + withoutSign
   } else {
      return "-" + withoutSign
   }
}

export { formatPercentTwoDecimals, formatPercentTwoDecimalsWithSign }
