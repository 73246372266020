<template>
   <QPageSticky
      id="q-page-sticky"
      :offset="visualEditorStore.offset"
      position="top-right"
   >
      <div id="ve-container" v-touch-pan.prevent.mouse="moveVisualEditor">
         <q-badge
            rounded
            :color="sdkStatusColor"
            floating
            style="transition: background 2s"
         ></q-badge>
         <QImg
            id="ezbot-logo"
            src="../../assets/ezbot-logo.png"
            width="150px"
         ></QImg>
         <QSeparator class="q-mt-md q-mb-md" size="lg" color="white" />

         <QInput
            v-model="iframeSource"
            filled
            dark
            dense
            color="primary"
            placeholder="Enter URL to preview"
         >
            <template v-slot:after>
               <q-icon
                  color="white"
                  name="mdi-creation"
                  @click="updateIframeSource"
                  class="cursor-pointer"
               />
            </template>
         </QInput>
         <div id="mode-selector-container">
            <q-btn-group>
               <q-btn
                  round
                  v-if="visualEditorStore.mode == 'ezbot'"
                  color="primary"
                  icon="mdi-cursor-default-click"
                  @click="toggleMode"
               >
                  <q-tooltip class="bg-deep-orange-9" :offset="[10, 10]">
                     Enable Interactive Mode
                  </q-tooltip></q-btn
               >
               <q-btn
                  round
                  v-else
                  color="primary"
                  icon="mdi-cursor-pointer"
                  @click="toggleMode"
               >
                  <q-tooltip class="bg-deep-orange-9" :offset="[10, 10]">
                     Enable Ezbot Mode
                  </q-tooltip></q-btn
               >
               <q-btn
                  v-if="false"
                  round
                  color="green"
                  icon="mdi-xml"
                  @click="toggleView"
               ></q-btn>
               <q-btn
                  v-if="false"
                  round
                  color="orange"
                  icon="mdi-menu"
                  @click="toggleView"
               >
               </q-btn>
               <q-btn
                  v-if="visualEditorStore.highlightEnabled"
                  round
                  color="light-blue-12"
                  icon="mdi-eye"
                  @click="toggleHighlight"
               >
                  <q-tooltip class="bg-deep-orange-9" :offset="[10, 10]">
                     Disable Highlighting
                  </q-tooltip></q-btn
               >
               <q-btn
                  v-else
                  round
                  color="light-blue-12"
                  icon="mdi-eye-off"
                  @click="toggleHighlight"
               >
                  <q-tooltip class="bg-deep-orange-9" :offset="[10, 10]">
                     Enable Highlighting
                  </q-tooltip></q-btn
               >
               <q-btn
                  v-if="visualEditorStore.shuffleEnabled"
                  round
                  color="purple"
                  icon="mdi-shuffle-variant"
                  @click="toggleShuffle"
               >
                  <q-tooltip class="bg-deep-orange-9" :offset="[10, 10]">
                     Disable Shuffle
                  </q-tooltip></q-btn
               >
               <q-btn
                  v-else
                  round
                  color="purple"
                  icon="mdi-shuffle-disabled"
                  @click="toggleShuffle"
               >
                  <q-tooltip class="bg-deep-orange-9" :offset="[10, 10]">
                     Enable Shuffle
                  </q-tooltip></q-btn
               >
            </q-btn-group>
         </div>
         <div
            v-if="
               visualEditorStore.selectedElement &&
               visualEditorStore.view == 'basic'
            "
            id="element-variables-container"
         >
            <BasicEditor />
         </div>
         <div
            id="selected-element-container"
            v-if="
               visualEditorStore.selectedElement &&
               visualEditorStore.view == 'advanced'
            "
         >
            <AdvancedElementEditor />
         </div>
         <div id="back-to-ezbot-container">
            <QBtn
               block
               color="secondary"
               class="full-width"
               text-color="white"
               label="back to ezbot"
               :to="`/orgs/${urlSlug}/projects/${projectStore.projectId}`"
            />
         </div>
      </div>
   </QPageSticky>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import {
   QInput,
   QIcon,
   QPageSticky,
   QImg,
   QSeparator,
   QBtn,
   QBtnGroup,
   TouchPanValue,
   QBadge,
   QTooltip,
} from "quasar"
import { useVisualEditorStore } from "../../stores/visualEditorStore"
import AdvancedElementEditor from "./element-editors/advanced/ElementCodeEditor.vue"
import { useProjectStore } from "../../stores"
import BasicEditor from "./basic-editors/BasicEditor.vue"
import { getOrg } from "@/services/clerk"

const projectStore = useProjectStore()
const visualEditorStore = useVisualEditorStore()
const localProjectSettings = await projectStore.getProjectSettings(
   projectStore.projectId!,
)
if (!visualEditorStore.url) {
   visualEditorStore.url =
      localProjectSettings.settings.visualEditor?.defaultUrl
}
if (localProjectSettings.settings.visualEditor?.highlightColor) {
   visualEditorStore.highlightColor =
      localProjectSettings.settings.visualEditor.highlightColor
}
const iframeSource = ref(visualEditorStore.url)
const $emit = defineEmits(["update-iframe-source"])

const updateIframeSource = () => {
   $emit("update-iframe-source", iframeSource.value)
}
const advancedButtonColor = ref("green")
visualEditorStore.selectedElement = null

const toggleMode = () => {
   visualEditorStore.selectedElement = null
   visualEditorStore.toggleMode()
}
const dragging = ref(false)

const toggleView = () => {
   visualEditorStore.toggleView()
   if (visualEditorStore.view == "advanced") {
      advancedButtonColor.value = "green"
   } else {
      advancedButtonColor.value = "orange"
   }
}

const toggleHighlight = () => {
   visualEditorStore.toggleHighlight()
}
const toggleShuffle = () => {
   visualEditorStore.toggleShuffle()
}

const org = await getOrg()
if (!org) {
   throw new Error("Org is undefined")
}
const { slug } = org
const urlSlug = ref(slug)

const sdkStatusColor = computed(() => {
   if (visualEditorStore.status == "ready") {
      return "green"
   } else if (visualEditorStore.status == "loading") {
      return "yellow"
   } else {
      return "red"
   }
})

const moveVisualEditor: TouchPanValue = (ev) => {
   if (!ev.delta?.x || !ev.delta?.y) {
      return
   }
   dragging.value = ev.isFirst !== true && ev.isFinal !== true
   visualEditorStore.offset = [
      visualEditorStore.offset[0] - ev.delta.x,
      visualEditorStore.offset[1] + ev.delta.y,
   ]
}
</script>
<style lang="scss" scoped>
#q-page-sticky {
   z-index: 99999;
}
#ve-container {
   padding: 25px;
   justify-content: center;
   width: 450px;
   background: rgb(94, 1, 117);
   border-radius: 10px;
   box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
   cursor: grab;
   min-height: 250px;
}
#mode-selector-container {
   display: flex;
   gap: 10px;
}
.mode-selector {
   border: 1px solid white;
   padding: 5px;
   border-radius: 5px;
}
#selected-element-container {
   margin-top: 20px;
   text-align: left;
   color: white;
}
#back-to-ezbot-container {
   margin-top: 20px;
}
</style>
