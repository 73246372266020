<template>
   <div class="bg-white q-pa-md">
      <template v-if="mode == 'edit'">
         <h1 class="text-left text-h4">Edit Variable</h1>
         <div class="chip-container">
            <QChip
               color="primary"
               text-color="white"
               v-if="type === 'basic'"
               id="variable-type-chip"
               >{{ _.capitalize(type) }}</QChip
            >
            <QChip
               v-else
               color="purple"
               text-color="white"
               id="variable-type-chip"
               >{{ _.capitalize(type) }}</QChip
            >
            <QChip id="variable-id-chip">Variable Id: {{ id }}</QChip>
         </div>
      </template>
      <template v-if="mode == 'create'">
         <h1 class="text-left text-h4">
            Create {{ _.capitalize(type) }} Variable
         </h1>
         <p id="explainer-text" class="text-left">
            A variable is the basic building block of a user experience. It is
            the thing you want to change, test, and improve upon. You can
            <a
               href="https://docs.ezbot.ai/reference-materials/glossary/"
               target="_blank"
            >
               read more about them here.</a
            >
         </p>
      </template>
      <EditVariable
         :variable="localVariable"
         :mode="mode"
         @type-update="updateType"
         @variable-submission="handleSubmit"
      />
   </div>
</template>
<script setup lang="ts">
import EditVariable from "../components/EditVariable.vue"
import { QChip, useQuasar } from "quasar"
import { ref, Ref } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { useRoute, useRouter } from "vue-router"
import { toast } from "vue3-toastify"
import _ from "lodash"
import { EditableVariable } from "../types/variable"
import { AxiosError } from "axios"
import { collectError } from "../utils"

const projectStore = useProjectStore()
const route = useRoute()
const router = useRouter()
const $q = useQuasar()
const mode: Ref<"create" | "edit"> = ref("create")

const blankVariable: EditableVariable = {
   humanReadableName: "",
   type: "basic",
   version: "0.1",
   key: "",
   defaultValue: "",
   constraints: {
      enumerables: [],
   },
   config: null,
   projectId: 0,
   constraintsVersion: "0.1",
}
const localVariable: Ref<EditableVariable> = ref(blankVariable)
if (route.name === "EditVariable") {
   mode.value = "edit"
}
if (route.name === "CreateVariable") {
   mode.value = "create"
}

if (mode.value == "edit") {
   try {
      const dbv = await projectStore.getVariable(
         Number(route.params.variableId),
      )
      localVariable.value = {
         humanReadableName: dbv.humanReadableName,
         type: dbv.type,
         version: dbv.version,
         key: dbv.key,
         defaultValue: dbv.defaultValue,
         constraints: dbv.constraints,
         config: dbv.config,
         projectId: dbv.projectId,
         constraintsVersion: dbv.constraintsVersion,
      } as EditableVariable
   } catch (error) {
      collectError(error as Error)
      if (error instanceof AxiosError) {
         const msg = error.response?.data.message
         if (msg) {
            await router.push({
               name: "ProjectHome",
               params: { projectId: projectStore.projectId },
            })
            toast.error(msg)
         }
      } else {
         throw error
      }
   }
}

if (mode.value == "create") {
   if (projectStore.previousCreateVariableType?.value == "visual") {
      localVariable.value = {
         humanReadableName: "",
         type: "visual",
         version: "0.2",
         key: "",
         defaultValue: "",
         constraints: {
            enumerables: [],
         },
         config: null,
         projectId: projectStore.projectId!,
         constraintsVersion: "0.1",
      }
   } else {
      localVariable.value = {
         humanReadableName: "",
         type: "basic",
         version: "0.1",
         key: "",
         defaultValue: "",
         constraints: {
            enumerables: [],
         },
         config: null,
         projectId: projectStore.projectId!,
         constraintsVersion: "0.1",
      }
   }
}
const id = ref(Number(route.params.variableId))
const type = ref(localVariable.value.type)
const updateType = (newType: "visual" | "basic") => {
   type.value = newType
}

const handleSubmit = async (variable: EditableVariable) => {
   $q.dialog({
      title: `${_.capitalize(mode.value)} Variable`,
      message: `Your dashboard analytics may not update for 24 hours. Are you sure you want to ${mode.value} this variable?`,
      cancel: true,
   }).onOk(async () => {
      try {
         if (mode.value === "edit") {
            await projectStore.updateVariable(
               Number(route.params.variableId),
               variable,
            )
            projectStore.variables =
               await projectStore.getVariablesByProjectId()
            router.push({ name: "Variables" }).then(() => {
               toast.success("Variable updated.")
            })
         } else {
            await projectStore.createVariable(variable)
            projectStore.variables =
               await projectStore.getVariablesByProjectId()
            router.push({ name: "Variables" }).then(() => {
               toast.success("Variable created.")
            })
         }
      } catch (error) {
         collectError(error as Error)
         if (error instanceof AxiosError) {
            const msg = error.response?.data.message
            if (msg) {
               toast.error(msg)
               return
            }
         }
         toast.error("Failed to create variable.")
         return
      }
   })
}
</script>
<style lang="scss" scoped>
h3 {
   margin: 0;
}
.chip-container {
   display: flex;
   align-items: left;
   margin-bottom: 1em;
}
#variable-id-chip {
   margin-left: 0;
}
#created-by-chip {
   margin-left: 0;
}
#variable-edit-form {
   border: 1px dotted darkgrey;
   padding: 1em;
   margin-bottom: 1em;
}
#variable-edit-form {
   width: 100%;
   max-width: 600px;
}
.q-field {
   margin-bottom: 10px;
}
#action-row {
   margin-top: 25px;
}
#explainer-text {
   max-width: 600px;
}
</style>
