import { trackEvent } from "@/analytics/trackEvent"
import {
   getNumberOfOrgs,
   getOrg,
   getOrgMemberships,
   hasActiveOrg,
   hasActiveSession,
   hasActiveStripeSubscription,
   refreshToken,
   setOnlyOrgAsActive,
} from "@/services/clerk"
import { addBreadcrumb } from "@/services/sentry"
import { useProjectStore } from "@stores/projectStore"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"

const checkOnboardingStepOne = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating onboarding step one for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const activeOrg = await hasActiveOrg()
   if (activeOrg) {
      return next({ name: "OnboardingCheckSubscription" })
   }

   return next()
}

const checkOnboardingStepTwo = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating onboarding step two for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      if (numOrgs === 1) {
         await setOnlyOrgAsActive()
      }
   }

   // an activeSubscription is determined by the presence of a stripe subscription
   // on the Clerk Organization's Public Metadata. Because we need this in the session token,
   // we need to call for a fresh token here to ensure we have the latest data.
   await refreshToken()
   const org = await getOrg()
   if (!org) {
      return next({ name: "OnboardingCreateProject" })
   }

   const { slug } = org
   const activeSubscription = await hasActiveStripeSubscription()

   if (activeSubscription) {
      return next({
         name: "CreateProject",
         params: { orgSlug: slug },
      })
   }

   return next({
      name: "SubscriptionError",
   })
}

const verifySubscription = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating subscription for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      if (numOrgs === 1) {
         await setOnlyOrgAsActive()
      }
   }

   // an activeSubscription is determined by the presence of a stripe subscription
   // on the Clerk Organization's Public Metadata. Because we need this in the session token,
   // we need to call for a fresh token here to ensure we have the latest data.
   await refreshToken()
   const org = await getOrg()
   if (!org) {
      return next({ name: "OnboardingCreateProject" })
   }

   const { slug } = org
   const activeSubscription = await hasActiveStripeSubscription()

   if (activeSubscription) {
      return next({
         name: "CreateProject",
         params: { orgSlug: slug },
      })
   }

   return next()
}

const checkOnboardingStepThree = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating onboarding step three for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const memberships = await getOrgMemberships()
   const membershipKeys = Object.keys(memberships)
   if (!memberships || membershipKeys.length === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      if (numOrgs === 1) {
         await setOnlyOrgAsActive()
      }
   }

   // an activeSubscription is determined by the presence of a stripe subscription
   // on the Clerk Organization's Public Metadata. Because we need this in the session token,
   // we need to call for a fresh token here to ensure we have the latest data.
   await refreshToken()

   const activeSubscription = await hasActiveStripeSubscription()
   if (!activeSubscription) {
      return next({ name: "OnboardingCheckSubscription" })
   }

   trackEvent("stripe_subscription_started")
   const projectStore = useProjectStore()
   await projectStore.getActiveOrgProjects()
   const projects = projectStore.projects

   if (projects && projects.length > 0) {
      if (projectStore.projectId) {
         return next({
            name: "ProjectHome",
            params: { projectId: projectStore.projectId },
         })
      }

      // TODO: should route to "OrgHome" for someone to pick a project
      return next({
         name: "ProjectHome",
         params: { projectId: projects[0].id },
      })
   }

   const org = await getOrg()
   if (!org) {
      return next({ name: "OnboardingCreateProject" })
   }

   const { slug } = org

   return next({
      name: "CreateProject",
      params: { orgSlug: slug },
   })
}

export {
   checkOnboardingStepOne,
   checkOnboardingStepTwo,
   checkOnboardingStepThree,
   verifySubscription,
}
