import { RouteLocationNormalized, NavigationGuardNext } from "vue-router"
import {
   checkOnboardingStepOne,
   checkOnboardingStepThree,
   checkOnboardingStepTwo,
   verifySubscription,
} from "../guards/onboarding"
import {
   CreateOrg,
   CheckSubscription,
   StripeSubscriptionCallback,
} from "@/views/onboarding"
import { SubscriptionError } from "@views/index"

export default [
   {
      path: "/onboarding/create-org",
      name: "OnboardingCreateOrg",
      component: CreateOrg,
      meta: {
         layout: "AppLayoutDefault", // should be OnboardingLayout so that users can log out, but there's a spacing issue
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return checkOnboardingStepOne(to, from, next)
      },
   },
   {
      path: "/onboarding/check-subscription",
      name: "OnboardingCheckSubscription",
      component: CheckSubscription,
      meta: {
         layout: "OnboardingLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return checkOnboardingStepTwo(to, from, next)
      },
   },
   {
      path: "/onboarding/stripe-callback",
      name: "StripeCallback",
      component: StripeSubscriptionCallback,
      meta: {
         layout: "OnboardingLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return checkOnboardingStepThree(to, from, next)
      },
   },
   {
      path: "/onboarding/subscription-error",
      name: "SubscriptionError",
      component: SubscriptionError,
      meta: {
         layout: "OnboardingLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return verifySubscription(to, from, next)
      },
   },
]
