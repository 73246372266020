<template>
   <div>
      <CodeEditor
         ref="editor"
         v-model="codeToRender"
         :languages="displayLanguages"
         :wrap="true"
         width="100%"
         font-size="12px"
      />
   </div>
</template>
<script setup lang="ts">
import { PropType, computed, ref, watch } from "vue"
import CodeEditor from "simple-code-editor/npm-package/CodeEditor.vue"
const supportedLanguagesMap = {
   // feel free to add more, but make sure to add the styles
   html: {
      language: "html",
      displayName: "HTML",
   },
}
// A language is an array with the first element being the language key and the second element being the display name
type LanguageKey = keyof typeof supportedLanguagesMap
// an enumerable of displayNames in the supportedLanguagesMap
type LanguageDisplayName =
   (typeof supportedLanguagesMap)[LanguageKey]["displayName"]
type InputLanguages = LanguageKey[]
type Language = [LanguageKey, LanguageDisplayName]

const displayLanguages = computed(() => {
   return props.languages.map((lang) => {
      return [lang, supportedLanguagesMap[lang].displayName] as Language
   })
})

const props = defineProps({
   code: {
      type: String,
      required: true,
   },
   languages: {
      type: Array as PropType<InputLanguages>,
      required: true,
   },
})
const codeToRender = ref(props.code)
const editor = ref<InstanceType<typeof CodeEditor>>()
watch(
   () => props.code,
   (newVal) => {
      codeToRender.value = newVal
   },
)
watch(
   () => codeToRender.value,
   (newVal) => {
      editor.value.val
      codeToRender.value = newVal
   },
)
</script>
