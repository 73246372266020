<template>
   <q-btn
      class="bg-purple-5 text-white basic-editor-action"
      push
      label="Outer HTML"
   >
      <q-badge
         rounded
         v-if="store.numVariationsOnActiveVariable === 0"
         :color="badgeColor(store.numVariationsOnActiveVariable)"
         floating
      ></q-badge>
      <q-badge
         v-else
         :color="badgeColor(store.numVariationsOnActiveVariable)"
         floating
         >{{ store.numVariationsOnActiveVariable }}</q-badge
      >
   </q-btn>
</template>
<script setup lang="ts">
import { QBtn, QBadge } from "quasar"
import { badgeColor } from "../shared"
import { useVisualEditorStore } from "../../../../stores/visualEditorStore"
const store = useVisualEditorStore()
</script>
<style lang="scss" scoped>
.basic-editor-action {
   margin: 5px;
}
</style>
../shared
