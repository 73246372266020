import { captureException } from "@/services/sentry"
import { useGtm } from "@gtm-support/vue-gtm"

const trackEvent = (conversionKey: string) => {
   if (import.meta.env.VITE_ENV != "production") {
      return
   }
   try {
      const gtm = useGtm()
      gtm?.trackEvent({
         event: conversionKey,
      })
   } catch (e) {
      captureException(e)
   }
   try {
      window.clarity("event", conversionKey)
   } catch (e) {
      captureException(e)
   }
}

export { trackEvent }
