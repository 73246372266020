<template>
   <div>
      <QTable :rows="rows" :columns="columns" row-key="id" title="Combinations">
         <template v-slot:body>
            <tr>
               <td v-for="column in columns" :key="column.name">
                  <QSkeleton />
               </td>
            </tr>
         </template>
      </QTable>
   </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { QTable, QSkeleton, QTableColumn } from "quasar"

const placeholderColumns: QTableColumn[] = [
   {
      name: "combination-number",
      label: "Combination",
      align: "left",
      field: "combination",
   },
   {
      name: "placeholder1",
      label: "Variable 1",
      align: "left",
      field: "placeholder",
   },
   {
      name: "placeholder2",
      label: "Variable 2",
      align: "left",
      field: "placeholder",
   },
   {
      name: "placeholder3",
      label: "...Variable N",
      align: "left",
      field: "placeholder",
   },
]

const combinationNumberColumn: QTableColumn = {
   name: "combination-number",
   label: "Combination",
   align: "left",
   field: "combination",
}

const conversionRateColumn: QTableColumn = {
   name: "conversion-rate",
   label: "Conversion Rate",
   align: "left",
   field: "conversionRate",
}
const conversionRateImprovementColumn: QTableColumn = {
   name: "conversion-rate-improvement",
   label: "Conversion Rate Improvement",
   align: "left",
   field: "conversionRateImprovement",
}
const trafficDistributionColumn: QTableColumn = {
   name: "traffic-distribution",
   label: "Traffic Distribution",
   align: "left",
   field: "trafficDistribution",
}

const columns = computed(() => {
   return [
      combinationNumberColumn,
      ...placeholderColumns,
      conversionRateColumn,
      conversionRateImprovementColumn,
      trafficDistributionColumn,
   ]
})

const rows = [
   {
      id: 1,
      combination: "1",
      placeholder1: "Placeholder",
      placeholder2: "Placeholder",
      placeholder3: "Placeholder",
      conversionRate: 0.5,
      conversionRateImprovement: 0.5,
      trafficDistribution: 0.5,
   },
   {
      id: 2,
      combination: "2",
      placeholder1: "Placeholder",
      placeholder2: "Placeholder",
      placeholder3: "Placeholder",
      conversionRate: 0.5,
      conversionRateImprovement: 0.5,
      trafficDistribution: 0.5,
   },
   {
      id: 3,
      combination: "3",
      placeholder1: "Placeholder",
      placeholder2: "Placeholder",
      placeholder3: "Placeholder",
      conversionRate: 0.5,
      conversionRateImprovement: 0.5,
      trafficDistribution: 0.5,
   },
]
</script>
