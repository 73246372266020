import { defineStore } from "pinia"
import { getSessionMetricsByProjectId } from "../services/adminBackendClient"
import { SessionMetrics, TimeBucket } from "../types"
import { useProjectStore } from "./projectStore"
import { toast } from "vue3-toastify"
import { collectError } from "@utils/errors"
import sessionStats from "@/mocks/sessionStats"
import { SummaryStats } from "@/types/sessionMetrics"
import { date } from "quasar"
// destructuring to keep only what is needed
const { getDateDiff } = date

type SessionMetricsState = {
   loading: boolean
   trace_id?: string
   query_start_date?: number
   start_date?: number
   query_end_date?: number
   end_date?: number
   as_of_time?: number
   agg_level?: string
   graphs_enabled?: boolean
   summary_stats?: SummaryStats
   time_series?: TimeBucket[]
}

export const useSessionMetricsStore = defineStore("sessionMetricsStore", {
   state: (): SessionMetricsState => {
      return {
         loading: false,
         trace_id: undefined,
         query_start_date: undefined,
         start_date: undefined,
         query_end_date: undefined,
         end_date: undefined,
         as_of_time: undefined,
         agg_level: undefined,
         graphs_enabled: undefined,
         summary_stats: undefined,
         time_series: undefined,
      }
   },
   persist: true,
   actions: {
      getStartDate(): number {
         const now = new Date()
         now.setDate(now.getDate() - 30)
         return Math.floor(
            (this.query_start_date ? this.query_start_date! : now.getTime()) /
               1000,
         )
      },
      getEndDate(): number {
         const now = new Date()
         now.setDate(now.getDate() + 1)
         return Math.floor(
            (this.query_end_date ? this.query_end_date! : now.getTime()) / 1000,
         )
      },
      async getSessionMetrics(
         startDate: string,
         endDate: string,
      ): Promise<SessionMetrics> {
         const startDt = Date.parse(startDate)
         const endDt = Date.parse(endDate)
         this.query_start_date = startDt
         this.query_end_date = endDt
         return this.updateMetrics()
      },
      async getSessionMetricsAggLevel(
         aggLevel: string,
      ): Promise<SessionMetrics> {
         this.agg_level = aggLevel
         return this.updateMetrics()
      },
      async updateMetrics(): Promise<SessionMetrics> {
         this.loading = true
         const projectStore = useProjectStore()
         const projectId = projectStore.projectId
         if (!projectId) throw new Error("Project ID is not set")
         let response = null
         try {
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = { data: sessionStats }
            } else {
               response = await getSessionMetricsByProjectId(
                  projectId,
                  this.getStartDate(),
                  this.getEndDate(),
                  this.agg_level ? this.agg_level : "24h",
               )
            }
         } catch (error) {
            collectError(error as Error)
            this.loading = false
            toast.error("Error occurred while fetching session metrics")
            throw error
         }
         this.trace_id = response.data.trace_id
         this.start_date = response.data.start_date
         this.end_date = response.data.end_date
         this.as_of_time = response.data.as_of_time
         this.agg_level = response.data.agg_level
         this.graphs_enabled = response.data.graphs_enabled
         this.summary_stats = response.data.summary_stats
         this.time_series = response.data.time_series
         this.loading = false

         return response.data
      },
   },
   getters: {
      daysBack(state): number {
         if (state.query_start_date) {
            return getDateDiff(Date.now(), state.query_start_date, "days")
         } else {
            return 30
         }
      },
      hasVariants(state): boolean {
         return (
            state.summary_stats?.variants !== null &&
            state.summary_stats?.variants !== undefined
         )
      },
   },
})
