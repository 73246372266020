<template>
   <QBtnDropdown color="primary" :label="aggLevelLabel" outline>
      <QList>
         <QItem clickable v-close-popup @click="selectAggLevel('24h')">
            <QItemSection>
               <QItemLabel>24h</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectAggLevel('12h')">
            <QItemSection>
               <QItemLabel>12h </QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectAggLevel('6h')">
            <QItemSection>
               <QItemLabel>6h</QItemLabel>
            </QItemSection>
         </QItem>
      </QList>
   </QBtnDropdown>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useDashboardStore } from "../../stores/dashboardStore"
const dashboardStore = useDashboardStore()

const aggLevelLabel = computed(() => {
   if (dashboardStore.agg_level) {
      return dashboardStore.agg_level
   } else {
      return "Loading..."
   }
})
const selectAggLevel = async (aggLevel: string) => {
   if (aggLevel === "24h") {
      dashboardStore.agg_level! = "24h"
   } else if (aggLevel === "12h") {
      dashboardStore.agg_level! = "12h"
   } else if (aggLevel === "6h") {
      dashboardStore.agg_level! = "6h"
   } else if (aggLevel === "3h") {
      dashboardStore.agg_level! = "3h"
   } else if (aggLevel === "1h") {
      dashboardStore.agg_level! = "1h"
   } else {
      throw new Error("Unsupported aggregation level")
   }
   const variableIds = dashboardStore.variable_snapshot
      ? dashboardStore.variable_snapshot.ids
      : []
   await dashboardStore.updateDashboard(variableIds)
}
</script>
