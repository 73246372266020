import { RouteRecordRaw } from "vue-router"

import { NotFound } from "../views"

import orgsRoutes from "./subroutes/orgsRoutes"
import baseRoutes from "./subroutes/baseRoutes"
import onboardingRoutes from "./subroutes/onboardingRoutes"
import projectsRoutes from "./subroutes/projectsRoutes"

const routes: RouteRecordRaw[] = [
   ...baseRoutes,
   ...onboardingRoutes,
   ...orgsRoutes,
   ...projectsRoutes,
   {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
   },
]

export default routes
