<template>
   <div id="sign-in-container">
      <SignIn
         path="/sign-in"
         sign-up-url="/sign-up"
         fallback-redirect-url="/authorize"
         routing="path"
      />
   </div>
</template>

<script lang="ts" setup>
import { SignIn } from "vue-clerk"
import { useHead } from "@unhead/vue"
useHead({
   title: "Sign In - ezbot.ai",
   meta: [
      {
         name: "description",
         content: "Sign in with your ezbot.ai account",
      },
      {
         property: "og:title",
         content: "Sign In - ezbot.ai",
      },
      {
         property: "og:description",
         content: "Sign in with your ezbot.ai account",
      },
      {
         property: "og:url",
         content: "https://app.ezbot.ai/sign-in",
      },
      {
         property: "twitter:title",
         content: "Sign In - ezbot.ai",
      },
      {
         property: "twitter:description",
         content: "Sign in with your ezbot.ai account",
      },
      {
         property: "twitter:url",
         content: "https://app.ezbot.ai/sign-in",
      },
   ],
   link: [
      {
         rel: "canonical",
         href: "https://app.ezbot.ai/sign-in",
      },
   ],
})
</script>

<style lang="scss" scoped>
#sign-in-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #f5f5f5;
   background-image: linear-gradient(to top right, #007bff, #00aaff);
}
</style>
