<template>
   <QBtnDropdown color="primary" :label="timeSelectionLabel">
      <QList>
         <QItem clickable v-close-popup @click="selectTimeRange(30)">
            <QItemSection>
               <QItemLabel>Last 30 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(14)">
            <QItemSection>
               <QItemLabel>Last 14 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(7)">
            <QItemSection>
               <QItemLabel>Last 7 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(3)">
            <QItemSection>
               <QItemLabel>Last 3 Days</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectTimeRange(1)">
            <QItemSection>
               <QItemLabel>Yesterday</QItemLabel>
            </QItemSection>
         </QItem>
      </QList>
   </QBtnDropdown>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useDashboardStore } from "../../stores/dashboardStore"
import { date } from "quasar"
const { formatDate, subtractFromDate, addToDate } = date
const dashboardStore = useDashboardStore()

const timeSelectionsMap = new Map([
   [30, "30 Days"],
   [14, "14 Days"],
   [7, "7 Days"],
   [3, "3 Days"],
   [1, "Yesterday"],
] as [number, string][])

const timeSelectionLabel = computed(() => {
   if (dashboardStore.daysBack! <= 30) {
      return timeSelectionsMap.get(dashboardStore.daysBack!)
   } else {
      return timeSelectionsMap.get(30)
   }
})

const selectTimeRange = async (daysBack: number) => {
   const startDateString = formatDate(
      subtractFromDate(Date.now(), { days: daysBack }),
      "YYYY/MM/DD",
   )

   const endDateString = formatDate(
      addToDate(Date.now(), { days: 1 }),
      "YYYY/MM/DD",
   )

   await dashboardStore.getDashboard(startDateString, endDateString)
}
</script>
