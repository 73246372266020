<template>
   <AbsoluteConversionRateGraph
      v-if="!props.showConversionRateImprovement"
      :conversionRateFilter="props.conversionRateFilter"
   />
   <ConversionRateImprovementGraph
      v-else
      :conversionRateFilter="props.conversionRateFilter"
   ></ConversionRateImprovementGraph>
</template>
<script setup lang="ts">
import ConversionRateImprovementGraph from "./ConversionRateImprovementGraph.vue"
import AbsoluteConversionRateGraph from "./AbsoluteConversionRateGraph.vue"
import { ConversionFilter } from "../../types/graphs"
import { PropType } from "vue"
const props = defineProps({
   showConversionRateImprovement: {
      type: Boolean,
      required: true,
   },
   conversionRateFilter: {
      type: String as PropType<ConversionFilter>,
      required: true,
   },
})
</script>
