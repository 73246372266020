<template>
   <div class="variation-editor">
      <div id="variation-container" class="text-left">
         <h5 class="text-left">Allowed Values</h5>
         <ul id="allowed-values-instructions" class="text-left">
            <li>Default values are indicated by a check mark.</li>
            <li>Click on a value to set it as the default.</li>
         </ul>
         <q-btn
            v-if="showDelete"
            round
            id="delete-button"
            clickable
            push
            @click="visualEditorStore.deleteActiveVariable"
            color="red-13"
            size="sm"
         >
            <q-icon name="mdi-delete" size="xs" color="white" />
         </q-btn>
         <div id="chip-container" ref="chipContainer" :class="{ error: error }">
            <template
               v-for="vc in visualEditorStore.allValueChoices"
               v-bind:key="vc"
            >
               <q-chip
                  removable
                  clickable
                  v-model:selected="vc.default"
                  @click="
                     visualEditorStore.setDefaultOnActiveVariable(vc.value)
                  "
                  @remove="
                     visualEditorStore.removeValueFromActiveVariable(vc.value)
                  "
                  color="purple-5"
                  size="md"
                  text-color="white"
                  :label="vc.value"
               >
               </q-chip>
            </template>
         </div>
         <div id="add-allowed-value">
            <!-- <QChip
                v-if="errorMessage"
                :label="errorMessage"
                size="sm"
                color="red-5"
                class="error-message-chip"
             >
             </QChip> -->
            <q-input
               ref="newAllowedValueInput"
               v-model="newAllowedValue"
               label="Add Allowed Value"
               bg-color="white"
               lazy-rules
               :error="error"
               :color="error ? 'red' : 'purple-5'"
               filled
               :class="newAllowedValueInputClasses"
               @keyup.enter.prevent="handleAddValue"
               @keyup.prevent="error = false"
            >
               <template v-slot:append>
                  <q-icon
                     name="mdi-plus-box"
                     @click="handleAddValue"
                     class="cursor-pointer q-ml-lg"
                     color="purple-5"
                  />
               </template>
            </q-input>

            <QBtn
               class="bg-purple-5 text-white save-button"
               push
               label="save"
               @click="visualEditorStore.saveActiveVariable"
            ></QBtn>
         </div>
      </div>
   </div>
</template>
<script setup lang="ts">
import { QBtn } from "quasar"
import { QInput, QIcon, QChip } from "quasar"
import { VNodeRef, computed, ref } from "vue"
import { useVisualEditorStore } from "../../../../stores/visualEditorStore"
const visualEditorStore = useVisualEditorStore()
const error = ref(false)
const newAllowedValueInput: VNodeRef = ref(null)
const chipContainer: VNodeRef = ref(null)
const newAllowedValue = ref("")

const showDelete = computed(() => {
   return visualEditorStore.activeVariable?.id ? true : false
})

const newAllowedValueInputClasses = computed(() => {
   return {
      "new-allowed-value-input": true,
      error: error.value,
   }
})
const handleAddValue = () => {
   visualEditorStore.addValueToActiveVariable(newAllowedValue.value)
   newAllowedValue.value = ""
}
</script>
<style lang="scss" scoped>
.variation-editor {
   background-color: purple;
   color: white;
   padding: 10px;
   width: 100%;
   min-height: 200px;
   margin-top: 15px;
}
h5 {
   margin-top: 0;
   margin-bottom: 10px;
}
#chip-container {
   display: flex;
   flex-wrap: wrap;
   gap: 5px;
   min-height: 100px;
   width: 100%;
   outline: 1px dotted $purple-2;
   margin: 10px 0;
   padding: 5px;
   &.error {
      outline: 1px dotted $pink-1;
   }
}
#add-allowed-value {
   width: 100%;
}
#allowed-values-instructions {
   padding-inline-start: 15px;
}
#error-message {
   font-weight: bold;
   color: $pink-1;
   text-align: left;
}
.save-button {
   width: 100%;
}
.error-message-chip {
   color: white;
}
#variation-container {
   position: relative;
}
#delete-button {
   position: absolute;
   top: 5px;
   right: 5px;
}
</style>
