import { ChartDataset, Point } from "chart.js"
import { useSessionMetricsStore } from "@/stores"

// function createVariantConversionRateDatasets(
//    sessionMetrics: SessionMetrics,
// ) {
//    const variant_cr: Map<string, Array<number>> = new Map()
//    const variant_lb: Map<string, Array<number>> = new Map()
//    const variant_ub: Map<string, Array<number>> = new Map()
//    sessionMetrics.time_series.forEach((x) => {
//       if (x.Dt && x.Stats.variants) {
//          for (const [key] of Object.entries(x.Stats.variants)) {
//             const value = x.Stats.variants[key]
//             if (variant_cr.has(key)) {
//                variant_cr.get(key).push(value.conversion_rate)
//                variant_lb.get(key).push(value.lb)
//                variant_ub.get(key).push(value.ub)
//             } else {
//                variant_cr.set(key, [value.conversion_rate])
//                variant_lb.set(key, [value.lb])
//                variant_ub.set(key, [value.ub])
//             }
//          }
//       }
//    })

//    const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
//    variant_cr.forEach((conversionRates, variant, m) => {
//       datasets.push(
//          {
//             label: `${variant} UB`,
//             data: variant_ub.get(variant),
//             backgroundColor: "rgba(54, 162, 235, 0.3)",
//             fill: "+1",
//             borderColor: "green",
//             pointBackgroundColor: "green",
//             pointRadius: 0,
//             showLine: false,
//          },
//          {
//             label: `${variant} LB`,
//             data: variant_lb.get(variant),
//             backgroundColor: "rgba(54, 162, 235, 0.3)",
//             fill: "+1",
//             borderColor: "green",
//             pointBackgroundColor: "green",
//             pointRadius: 0,
//             showLine: false,
//          },
//          {
//             label: `${variant} Conversion Rate`,
//             data: conversionRates,
//             backgroundColor: "rgba(54, 162, 235, 0.3)",
//             fill: "+1",
//             borderColor: "green",
//             pointBackgroundColor: "green",
//             pointRadius: 0,
//             showLine: false,
//          },
//       )
//    })
//    return datasets
// }

function createVariantLiftDatasets():
   | ChartDataset<"line", (number | Point | null)[]>[]
   | undefined {
   const sessionMetricsStore = useSessionMetricsStore()
   const variant_cr: Map<string, Array<number>> = new Map()
   sessionMetricsStore.time_series!.forEach((x) => {
      if (x.Dt) {
         if (!sessionMetricsStore.summary_stats!.variants) return undefined
         for (const key of Object.keys(
            sessionMetricsStore.summary_stats!.variants,
         )) {
            if (!x.Stats!.variants) return undefined
            const v = new Map(Object.entries(x.Stats.variants)).get(key)
            if (variant_cr.has(key)) {
               if (key in x.Stats.variants) {
                  variant_cr.get(key)?.push(v?.conversion_rate ?? 0.0)
               } else {
                  variant_cr.get(key)?.push(0.0)
               }
            } else {
               if (key in x.Stats.variants) {
                  variant_cr.set(key, [v?.conversion_rate ?? 0.0])
               } else {
                  variant_cr.set(key, [0.0])
               }
            }
         }
      }
   })
   const variant_lift: Map<string, Array<number>> = new Map()
   const default_cr: Array<number> | undefined = variant_cr.get("0")
   for (const [key, value] of variant_cr.entries()) {
      if (default_cr != undefined)
         variant_lift.set(
            key,
            default_cr?.map((e, i) => {
               if (value[i] > 0) {
                  return (value[i] / e) * 100 - 100
               } else return 0
            }),
         )
   }

   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
   variant_lift.forEach((conversionRates, variant) => {
      // pick the next color in colors, repeating only when the list has been gone through
      datasets.push({
         label: `${variant}`,
         data: conversionRates.map((x) => parseFloat(x.toFixed(2))),
         // backgroundColor: backgroundColor,
         // borderColor: borderColor,
         // pointBackgroundColor: "rgb(54, 162, 235)",
         pointRadius: 0,
         // showLine: false,
      })
   })
   return datasets
}

function createConversionRateByCombinationDatasets():
   | ChartDataset<"line", (number | Point | null)[]>[]
   | undefined {
   const sessionMetricsStore = useSessionMetricsStore()
   const variant_cr: Map<string, Array<number>> = new Map()
   sessionMetricsStore.time_series!.forEach((x) => {
      if (x.Dt) {
         if (!sessionMetricsStore.summary_stats!.variants) return undefined
         for (const key of Object.keys(
            sessionMetricsStore.summary_stats!.variants,
         )) {
            if (!x.Stats!.variants) return undefined
            const v = new Map(Object.entries(x.Stats.variants)).get(key)
            if (variant_cr.has(key)) {
               if (key in x.Stats.variants) {
                  variant_cr.get(key)?.push(v?.conversion_rate ?? 0.0)
               } else {
                  variant_cr.get(key)?.push(0.0)
               }
            } else {
               if (key in x.Stats.variants) {
                  variant_cr.set(key, [v?.conversion_rate ?? 0.0])
               } else {
                  variant_cr.set(key, [0.0])
               }
            }
         }
      }
   })
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []

   variant_cr.forEach((conversionRates, variant) => {
      datasets.push({
         label: `${variant}`,
         data: conversionRates.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         // backgroundColor: backgroundColor,
         // borderColor: borderColor,
         // pointBackgroundColor: "rgb(54, 162, 235)",
         pointRadius: 0,
         // showLine: false,
      })
   })
   return datasets
}

// function createExpVsHbDatasets(resp: AxiosResponse<SessionMetrics>) {
//    const ub: number[] = resp.data.time_series
//       .map((x) => {
//          if (x.Dt) return x.Stats.exp.ub
//          else return null
//       })
//       .filter((x) => !!x)
//    const avg: number[] = resp.data.time_series
//       .flatMap((x) => {
//          if (x.Dt) return x.Stats.exp.conversion_rate
//          else return null
//       })
//       .filter((x) => !!x)
//    const lb: number[] = resp.data.time_series
//       .map((x) => {
//          if (x.Dt) return x.Stats.exp.lb
//          else return null
//       })
//       .filter((x) => !!x)
//    const hb: number[] = resp.data.time_series
//       .map((x) => {
//          if (x.Dt) return x.Stats.hb.conversion_rate
//          else return null
//       })
//       .filter((x) => !!x)
//    const datasets: ChartDataset<"line", (number | Point | null)[]>[] = [
//       {
//          label: "Optimized UB",
//          data: ub,
//          backgroundColor: "rgba(54, 162, 235, 0.3)",
//          fill: "+1",
//          borderColor: "green",
//          pointBackgroundColor: "green",
//          pointRadius: 0,
//          showLine: false,
//       },
//       {
//          label: "Optimized Avg",
//          data: avg,
//          borderColor: "blue",
//          pointBackgroundColor: "blue",
//          pointRadius: 0,
//       },
//       {
//          label: "Optimized LB",
//          data: lb,
//          fill: "-1",
//          backgroundColor: "rgba(54, 162, 235, 0.3)",
//          borderColor: "",
//          pointBackgroundColor: "purple",
//          pointRadius: 0,
//          showLine: false,
//       },
//       {
//          label: "Holdback",
//          data: hb,
//          borderColor: "darkgrey",
//          pointRadius: 0,
//       },
//    ]
//    return datasets
// }

// // Timeseries. Display like "contextual bandit graph"
function createVariationServeRateDatasets():
   | ChartDataset<"line", (number | Point | null)[]>[]
   | undefined {
   const sessionMetricsStore = useSessionMetricsStore()
   const variant_serve_rates: Map<string, Array<number>> = new Map()

   sessionMetricsStore.time_series!.forEach((x) => {
      if (x.Dt) {
         if (!sessionMetricsStore.summary_stats!.variants) return undefined
         for (const key of Object.keys(
            sessionMetricsStore.summary_stats!.variants,
         )) {
            if (!x.Stats.variants) return undefined
            const variant = new Map(Object.entries(x.Stats.variants)).get(key)
            if (variant != undefined) {
               const servingRate = (variant.sessions / x.Stats.sessions) * 100

               if (variant_serve_rates.has(key)) {
                  variant_serve_rates.get(key)?.push(servingRate)
               } else {
                  variant_serve_rates.set(key, [servingRate])
               }
            } else {
               if (variant_serve_rates.has(key)) {
                  variant_serve_rates.get(key)?.push(0.0)
               } else {
                  variant_serve_rates.set(key, [0.0])
               }
            }
         }
      }
   })
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
   variant_serve_rates.forEach((servingRate, variant) => {
      datasets.push({
         label: `${variant}`,
         data: servingRate.map((x) => parseFloat(x.toFixed(2))),
         // backgroundColor: backgroundColor,
         fill: "origin",
         // borderColor: "green",
         // pointBackgroundColor: backgroundColor,
         pointRadius: 0,
         showLine: true,
      })
   })
   return datasets
}

function createOptimizedVsDefaultDatasets(
   showConfidenceIntervals: boolean = false,
): ChartDataset<"line", (number | Point | null)[]>[] | undefined {
   const sessionMetricsStore = useSessionMetricsStore()
   const timeseries: Map<string, Array<number>> = new Map()
   timeseries.set("exp_cr", [])
   timeseries.set("hb_cr", [])
   if (showConfidenceIntervals) {
      timeseries.set("exp_ub", [])
      timeseries.set("exp_lb", [])
      timeseries.set("hb_ub", [])
      timeseries.set("hb_lb", [])
   }
   sessionMetricsStore.time_series!.forEach((timeBucket) => {
      if (!timeBucket.Stats!.exp) return undefined
      const expData = timeBucket.Stats.exp
      const hbData = timeBucket.Stats.hb
      if (
         expData &&
         expData.window_conversion_rate !== undefined &&
         expData.window_lb !== undefined &&
         expData.window_ub !== undefined
      ) {
         timeseries
            .get("exp_cr")
            ?.push(expData.window_conversion_rate as number)
         if (showConfidenceIntervals) {
            timeseries.get("exp_ub")?.push(expData.window_ub as number)
            timeseries.get("exp_lb")?.push(expData.window_lb as number)
         }
      } else {
         timeseries.get("exp_cr")?.push(0.0)
         if (showConfidenceIntervals) {
            timeseries.get("exp_ub")?.push(0.0)
            timeseries.get("exp_lb")?.push(0.0)
         }
      }
      if (hbData) {
         timeseries.get("hb_cr")?.push(hbData.window_conversion_rate as number)
         if (showConfidenceIntervals) {
            timeseries.get("hb_ub")?.push(hbData.window_ub as number)
            timeseries.get("hb_lb")?.push(hbData.window_lb as number)
         }
      } else {
         timeseries.get("hb_cr")?.push(0.0)
         if (showConfidenceIntervals) {
            timeseries.get("hb_ub")?.push(0.0)
            timeseries.get("hb_lb")?.push(0.0)
         }
      }
   })
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []
   if (showConfidenceIntervals) {
      datasets.push({
         label: "Optimized UB",
         data: timeseries.get("exp_ub")!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         backgroundColor: "rgba(128,0,247,0.3)",
         fill: "+1",
         borderColor: "rgba(128,0,247,0.3)",
         pointRadius: 0,
         showLine: false,
      })
   }
   datasets.push({
      label: "Optimized",
      data: timeseries.get("exp_cr")!.map((x) => {
         const y = x * 100
         return parseFloat(y.toFixed(2))
      }),
      backgroundColor: "#7E00F7",
      borderColor: "#7E00F7",
      pointRadius: 0,
   })
   if (showConfidenceIntervals) {
      datasets.push({
         label: "Optimized LB",
         data: timeseries.get("exp_lb")!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         fill: "-1",
         backgroundColor: "rgba(128,0,247,0.3)",
         borderColor: "rgba(128,0,247,0.3)",
         pointRadius: 0,
         showLine: false,
      })
      datasets.push({
         label: "Default UB",
         data: timeseries.get("hb_ub")!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         backgroundColor: "rgba(110,110,110,0.1)",
         fill: "+1",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }
   datasets.push({
      label: "Default",
      data: timeseries.get("hb_cr")!.map((x) => {
         const y = x * 100
         return parseFloat(y.toFixed(2))
      }),
      backgroundColor: "#6e6e6e",
      borderColor: "#6e6e6e",
      pointRadius: 0,
   })
   if (showConfidenceIntervals) {
      datasets.push({
         label: "Default LB",
         data: timeseries.get("hb_lb")!.map((x) => {
            const y = x * 100
            return parseFloat(y.toFixed(2))
         }),
         fill: "-1",
         backgroundColor: "rgba(110,110,110,0.1)",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }
   return datasets
}

export {
   //    createVariantConversionRateDatasets,
   createConversionRateByCombinationDatasets,
   createVariantLiftDatasets,
   createOptimizedVsDefaultDatasets,
   //    createExpVsHbDatasets,
   createVariationServeRateDatasets,
}
