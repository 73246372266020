<template>
   <div>
      <QTable
         :rows="projectStore.attributes"
         :columns="columns"
         row-key="name"
         :rows-per-page-options="[0]"
         :pagination="{
            page: 1,
            rowsPerPage: 0, // 0 means all rows
            sortBy: 'updatedAt',
            descending: true,
         }"
         title="User Attributes"
         no-data-label="No attributes found."
         hide-bottom
         @row-click="onRowClick"
      >
      </QTable>
      <div id="action-container" class="row justify-end">
         <QBtn
            id="create-attribute"
            color="primary"
            label="Create Attribute"
            @click="handleCreateAttribute"
         >
         </QBtn>
      </div>
   </div>
</template>

<script setup lang="ts">
import { QTable, QBtn, QTableColumn } from "quasar"
import { useRouter } from "vue-router"
import { format } from "timeago.js"
import { useProjectStore } from "../stores"
import { Router } from "vue-router"
import { toast } from "vue3-toastify"

// TODO: put types in a better place
interface Attribute {
   humanReadableName: string
   id: number
   key: string
   createdBy: number
   createdAt: Date
   updatedAt: Date
}

const router: Router = useRouter()
const projectStore = useProjectStore()
if (projectStore.projectId == null) {
   throw new Error("Cannot load Attributes page without a projectId.")
}
await projectStore.getAttributesByProjectId(projectStore.projectId)

const columns: QTableColumn[] = [
   {
      name: "humanReadableName",
      align: "left",
      label: "Human Readable Name",
      field: (row: Attribute) => row.humanReadableName,
      sortable: true,
   },
   {
      name: "id",
      required: true,
      label: "Attribute Id",
      align: "center",
      field: (row: Attribute) => row.id,
      sortable: true,
   },
   {
      name: "key",
      align: "center",
      label: "Variable Key",
      field: (row: Attribute) => row.key,
      sortable: true,
   },
   {
      name: "createdBy",
      align: "center",
      label: "Created By",
      field: (row: Attribute) => {
         if (row.createdBy == 1) {
            return "You"
         } else {
            return row.createdBy
         }
      },
      sortable: true,
   },
   {
      name: "createdAt",
      align: "center",
      label: "Created",
      field: (row: Attribute) => row.createdAt,
      format: (val: string) => format(val),
      sortable: true,
   },
   {
      name: "updatedAt",
      align: "center",
      label: "Updated",
      field: (row: Attribute) => row.updatedAt,
      format: (val: string) => format(val),
      sortable: true,
   },
]

const onRowClick = () => {
   toast.warning("Editing and deleting an attribute is not supported yet.")
   // router.push({ name: "Attribute", params: { attributeId: row.id } })
}
const handleCreateAttribute = () => {
   router.push({ name: "CreateAttribute" })
}
</script>
<style lang="scss">
#action-container {
   margin-top: 1em;
}
</style>
