import { defineStore } from "pinia"

type AppState = {
   counter: number
   leftDrawerOpen: boolean
}

export const useAppState = defineStore("appState", {
   state: (): AppState => {
      return {
         counter: 0,
         leftDrawerOpen: false,
      }
   },
   persist: true,
})
