<template>
   <QBadge outline color="secondary" style="font-size: 1em"
      ><QIcon class="q-mr-sm" :name="$props.icon" size="sm" />
      {{ $props.prefix }}:
      <div class="text-bold q-ml-sm flex flex-col" style="align-items: center">
         <CountUp :end-val="$props.firstNumber" :options="baseCountUpOptions">
         </CountUp>
         <span>&nbsp;(</span>
         <div>
            <CountUp
               v-if="$props.secondNumber"
               :end-val="$props.secondNumber"
               :options="baseCountUpOptions"
            >
            </CountUp>
         </div>
         <span>)</span>
         <KPITooltip
            style="margin-left: 2em"
            v-if="$props.tooltipText"
            :tooltip-text="$props.tooltipText"
         />
      </div>
   </QBadge>
</template>
<script setup lang="ts">
import { defineProps } from "vue"
import CountUp from "vue-countup-v3"
import KPITooltip from "./KPITooltip.vue"
defineProps({
   prefix: {
      type: String,
      required: true,
   },
   firstNumber: {
      type: Number,
      required: true,
   },
   tooltipText: {
      type: String,
      required: false,
   },
   secondNumber: {
      type: Number,
      required: false,
   },
   icon: {
      type: String,
      required: true,
   },
})

const baseCountUpOptions = {
   duration: 2,
   useEasing: true,
   useGrouping: true,
   separator: ",",
}
</script>
