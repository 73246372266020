<template>
   <div class="bg-white q-pa-md">
      <div id="main-container">
         <h1 class="text-left text-h4">Rewards</h1>
         <p id="intro">
            ezbot uses reward signals to score user sessions and optimize for
            your desired business outcomes. You must send at least one reward
            signal to ezbot as part of your installation. Learn more about
            <a
               href="https://docs.ezbot.ai/get-started/strategize/"
               target="_blank"
               >how to pick the right reward signal here</a
            >
            and
            <a
               href="https://docs.ezbot.ai/get-started/strategize/"
               target="_blank"
               >how to setup rewards here.
            </a>
         </p>
         <QForm id="rewards-edit-form" @submit="openSaveDialog">
            <h3 class="text-h5">Page View Events</h3>
            <p class="text-left">
               Optionally, you can use a page view event as a reward metric:
            </p>
            <QInput
               v-model="urlPattern"
               label="URL Pattern"
               hint="Page views matching this regular expression will count as rewards."
               :rules="[isValidRegex]"
            />
            <div id="submit-container">
               <QBtn
                  id="submit"
                  type="submit"
                  color="primary"
                  class="action-button"
                  :disable="showDisabledSubmit"
                  >Save</QBtn
               >
            </div>
         </QForm>
         <p id="after-form">
            <a
               href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions"
               target="_blank"
            >
               Learn more about regular expressions (regex)
            </a>
         </p>
      </div>
   </div>
</template>
<script setup lang="ts">
import { QForm, useQuasar } from "quasar"
import { ref, computed, ComputedRef } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { toast } from "vue3-toastify"
import { PageViewReward } from "../types/projectSettings"

const $q = useQuasar()
const projectStore = useProjectStore()
const localProjectSettings = await projectStore.getProjectSettings(
   projectStore.projectId!,
)
const urlPattern = ref("")
if (localProjectSettings.settings.rewards?.pageViews) {
   urlPattern.value =
      localProjectSettings.settings.rewards.pageViews[0].urlPattern
} else {
   // eslint-disable-next-line no-useless-escape
   urlPattern.value = "https:\/\/example.com\/checkouts\/*"
}

const isValidRegex = (pattern: string) => {
   try {
      new RegExp(pattern)
      return true
   } catch (e) {
      return "Must use valid Regex pattern"
   }
}

const pageViewRewards: ComputedRef<PageViewReward[]> = computed(() => {
   return [
      {
         type: "pageViewReward",
         urlPattern: urlPattern.value,
      } as PageViewReward,
   ]
})

const openSaveDialog = () => {
   $q.dialog({
      title: "Save Rewards",
      message:
         "Are you sure you want to save these rewards to your project settings?",
      cancel: true,
   }).onOk(async () => {
      await projectStore.updateProjectSettings(projectStore.projectId!, {
         settings: {
            holdbackPercentage:
               localProjectSettings.settings.holdbackPercentage,
            optimizationMetric:
               localProjectSettings.settings.optimizationMetric,
            rewards: {
               pageViews: pageViewRewards.value,
            },
            visualEditor: {
               defaultUrl:
                  localProjectSettings.settings.visualEditor?.defaultUrl,
               highlightColor:
                  localProjectSettings.settings.visualEditor?.highlightColor,
            },
         },
      })
      toast.success("Rewards saved")
   })
}

const showDisabledSubmit = computed((): boolean => {
   const urlPatternprojectSettings =
      localProjectSettings.settings.rewards?.pageViews
   return (
      urlPatternprojectSettings != undefined &&
      urlPatternprojectSettings === pageViewRewards.value
   )
})
</script>
<style lang="scss" scoped>
#main-container {
   width: 100%;
   max-width: 600px;
}
h3 {
   margin-bottom: 0.5em;
   text-align: left;
}
#rewards-edit-form {
   border: 1px dotted darkgrey;
   padding: 1em;
   margin-bottom: 1em;
   width: 100%;
}
.q-field {
   margin-bottom: 10px;
}
#after-form {
   width: 100%;
   font-style: italic;
   text-align: left;
   a {
      color: $grey-9;
   }
}
#intro {
   text-align: left;
   width: 100%;
   span {
      font-weight: bold;
   }
}
#submit-container {
   margin-top: 2em;
   width: 100%;
   text-align: right;
}
.text-left {
   text-align: left;
}
</style>
