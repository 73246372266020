<template>
   <div class="bg-white q-pa-md" id="home-main-container">
      <div
         id="top-container"
         v-if="sessionMetricsStore.summary_stats?.variants"
      >
         <h1 id="dashboard-title" class="text-h3 text-left text-bold q-mb-none">
            Data Explorer
         </h1>
         <div id="dropdown-container">
            <NewDashboardButton />
            <AggLevelSelector />
            <DatePickerSelector2 />
         </div>
      </div>
      <template v-if="sessionMetricsStore.summary_stats?.variants">
         <div id="kpi-container">
            <KPICard
               title="Sessions"
               icon="mdi-account-multiple"
               :number="sessionMetricsStore.summary_stats?.sessions"
               number-units="none"
               :size="inlineKPISize"
               subtitle="# of sessions observed"
            />
            <KPICard
               title="Predictions"
               icon="mdi-auto-fix"
               :number="sessionMetricsStore.summary_stats?.predictions"
               number-units="none"
               :size="inlineKPISize"
               subtitle="# of ezbot predictions made"
            />
            <KPICard
               title="Additional Conversions"
               icon="mdi-head-check"
               :number="
                  sessionMetricsStore.summary_stats
                     ?.addl_conversions_alt_version
               "
               number-units="none"
               color="green"
               :size="inlineKPISize"
               subtitle="Estimated addl. conversions from ezbot use"
            />
            <KPICard
               title="Additional Revenue"
               icon="mdi-currency-usd"
               number-units="none"
               :size="inlineKPISize"
               color="green"
               subtitle="Estimated addl. revenue from ezbot use"
            />
         </div>
      </template>
      <div v-else>
         <h1 class="text-h3 text-left text-bold">You're almost done!</h1>

         <p id="intro-paragraph" class="text-left">
            Your next step is to install the ezbot Web Tracker on your website
            following the instructions below. You will see your dashboard here
            once we start receiving data from your website. Please allow up to
            24 hours.
            <a href="https://docs.ezbot.ai/get-started/install/" target="_new"
               >Read our implementation guide here.</a
            >
         </p>
         <p id="project-id" class="text-left text-bold">
            <span>Your Project Id is: </span>{{ projectStore.projectId }}
         </p>
      </div>
      <div id="main-container">
         <div
            id="code-snippets-container"
            v-if="!sessionMetricsStore.summary_stats?.variants"
         >
            <CodeSnippets id="code-snippets" />
            <div id="mobile-install-instructions">
               <QCard id="mobile-install-card">
                  <QCardSection class="bg-primary">
                     <div class="text-h5 text-white">
                        Complete Installation on Desktop
                     </div>
                  </QCardSection>
                  <QCardSection>
                     <p class="text-left">
                        To complete the installation process, please continue on
                        a Desktop device. Your unique code snippets will be
                        here.
                     </p>
                  </QCardSection>
               </QCard>
            </div>
            <div>
               <QCard id="installation-support-card">
                  <QCardSection class="bg-primary">
                     <div class="text-h5 text-white">
                        Need help with your installation?
                     </div>
                  </QCardSection>
                  <QCardSection>
                     <p class="text-left">
                        ezbot offers with free white glove installation support
                        via Google Meet. Book your 30 minute install session
                        today
                        <a
                           href="https://calendly.com/griffin-ezbot/ezbot-install-support"
                           target="_blank"
                           >via calendly</a
                        >
                        or send us an email at
                        <a href="mailto:support@ezbot.ai">support@ezbot.ai.</a>
                        Our business hours are 9am-5pm Central Time, Monday to
                        Friday.
                     </p>
                  </QCardSection>
               </QCard>
            </div>
         </div>
         <div v-else id="graphs-container">
            <QCard class="graph-card">
               <QCardSection class="bg-primary">
                  <div class="text-h5 text-white">
                     ezbot vs. Default Conversion Rate
                  </div>
               </QCardSection>
               <QCardSection>
                  <div id="optimized-vs-default-graph-controls">
                     <QCheckbox
                        size="sm"
                        v-model="showOptimizedVsDefaultConfidenceIntervals"
                        :val="showOptimizedVsDefaultConfidenceIntervals"
                        label="Show 99% Confidence Intervals"
                     />
                  </div>
                  <OptimizedVsDefaultGraph
                     v-if="sessionMetricsStore.hasVariants"
                     :show-confidence-intervals="
                        showOptimizedVsDefaultConfidenceIntervals
                     "
                  />
               </QCardSection>
            </QCard>
            <QCard class="graph-card">
               <QCardSection class="bg-primary">
                  <div class="text-h5 text-white">Traffic Distribution</div>
               </QCardSection>
               <QCardSection>
                  <div id="traffic-distribution-graph-controls">
                     <QBtnDropdown :label="activeTrafficFilter.label" outline>
                        <QList>
                           <template
                              v-for="option in conversionFilterOptions"
                              v-bind:key="option.val"
                           >
                              <QItem
                                 clickable
                                 v-close-popup
                                 @click="onTrafficFilterClick(option)"
                              >
                                 <QItemSection>
                                    <QItemLabel>{{ option.label }}</QItemLabel>
                                 </QItemSection>
                              </QItem>
                           </template>
                        </QList>
                     </QBtnDropdown>
                  </div>
                  <TrafficDistributionGraph
                     v-if="sessionMetricsStore.hasVariants"
                     :trafficFilter="activeTrafficFilter.val"
                  />
               </QCardSection>
            </QCard>
            <QCard class="graph-card">
               <QCardSection class="bg-primary">
                  <div
                     v-if="!showConversionRateImprovement"
                     class="text-h5 text-white"
                  >
                     <span>Conversion Rate</span>
                  </div>
                  <div v-else class="text-h5 text-white">
                     <span>Conversion Rate Improvement</span>
                  </div>
               </QCardSection>
               <QCardSection>
                  <div id="conversion-rate-graph-controls">
                     <QBtnDropdown
                        :label="activeConversionRateFilter.label"
                        outline
                     >
                        <QList>
                           <template
                              v-for="option in conversionFilterOptions"
                              v-bind:key="option.val"
                           >
                              <QItem
                                 clickable
                                 v-close-popup
                                 @click="onConversionRateFilterClick(option)"
                              >
                                 <QItemSection>
                                    <QItemLabel>{{ option.label }}</QItemLabel>
                                 </QItemSection>
                              </QItem>
                           </template>
                        </QList>
                     </QBtnDropdown>
                     <QToggle
                        v-model="showConversionRateImprovement"
                        label="Relative to Default Conversion Rate"
                     />
                  </div>
                  <ConversionRateGraph
                     v-if="sessionMetricsStore.hasVariants"
                     id="conversion-graph"
                     class="graph"
                     :showConversionRateImprovement="
                        showConversionRateImprovement
                     "
                     :conversionRateFilter="activeConversionRateFilter.val"
                  ></ConversionRateGraph>
               </QCardSection>
            </QCard>
         </div>
      </div>
   </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import OptimizedVsDefaultGraph from "../components/OptimizedVsDefaultGraph.vue"
import TrafficDistributionGraph from "../components/TrafficDistributionGraph.vue"
import { useQuasar } from "quasar"
import ConversionRateGraph from "../components/conversion-rate-graph/ConversionRateGraph.vue"
import KPICard from "../components/KPICard.vue"
import { useProjectStore, useSessionMetricsStore } from "../stores"
import { onMounted } from "vue"
import { watch } from "vue"
import { CodeSnippets } from "@components/index"
import { ConversionFilterOption, TrafficFilterOption } from "../types/graphs"
import AggLevelSelector from "../components/AggLevelSelector.vue"
import NewDashboardButton from "../components/NewDashboardButton.vue"
import DatePickerSelector2 from "@components/DatePickerSelector2.vue"
import { date } from "quasar"
const { formatDate, subtractFromDate } = date

const $q = useQuasar()
const sessionMetricsStore = useSessionMetricsStore()
const projectStore = useProjectStore()

const showConversionRateImprovement = ref(false)
const showOptimizedVsDefaultConfidenceIntervals = ref(false)

const trafficFilterOptions: TrafficFilterOption[] = [
   {
      label: "All Combinations",
      val: "all",
   },
   {
      label: "Current Top 10",
      val: "top_10",
   },
   {
      label: "Current Bottom 10",
      val: "bottom_10",
   },
]

const conversionFilterOptions: ConversionFilterOption[] = [
   {
      label: "All Combinations",
      val: "all",
   },
   {
      label: "Current Top 10",
      val: "top_10",
   },
   {
      label: "Current Bottom 10",
      val: "bottom_10",
   },
]
const activeConversionRateFilter = ref(conversionFilterOptions[1])
const activeTrafficFilter = ref(trafficFilterOptions[1])

const onConversionRateFilterClick = (option: ConversionFilterOption) => {
   activeConversionRateFilter.value = option
}
const onTrafficFilterClick = (option: ConversionFilterOption) => {
   activeTrafficFilter.value = option
}

onMounted(() => {
   // Fetch variables for CombinationDetailsDialog
   projectStore.getActiveOrgProjects()
   projectStore.getVariablesByProjectId()
})

const inlineKPISize = computed(() => {
   if ($q.screen.width < 1200) {
      return "md"
   } else {
      return "lg"
   }
})
sessionMetricsStore.$subscribe((mutation, state) => {
   if (state.loading) {
      $q.loading.show()
   } else {
      $q.loading.hide()
   }
})

if (
   !sessionMetricsStore.query_start_date ||
   !sessionMetricsStore.query_end_date
) {
   const startDateString = formatDate(
      subtractFromDate(Date.now(), { days: 30 }),
      "YYYY/MM/DD",
   )

   const endDateString = formatDate(Date.now(), "YYYY/MM/DD")
   await sessionMetricsStore.getSessionMetrics(startDateString, endDateString)
} else {
   await sessionMetricsStore.updateMetrics()
}
watch(
   () => projectStore.projectId,
   async () => {
      if (projectStore.projectId) {
         projectStore.getVariablesByProjectId()
         sessionMetricsStore.updateMetrics()
      }
   },
)
</script>
<style lang="scss" scoped>
#main-container {
   width: 100%;
}
#kpi-container {
   display: flex;
   align-items: center;
   gap: 2em;
   width: 100%;
   padding-top: 1em;
   padding-bottom: 1em;
}
.kpi-card {
   width: 100%;
}
#graphs-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 2em;
}
.graph-card {
   width: 100%;
}

#top-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   padding-top: 1em;
   padding-bottom: 1em;
}
#dropdown-container {
   display: flex;
   gap: 1em;
   max-height: 40px;
}
#chips-container {
   display: flex;
   gap: 1em;
}
#conversion-rate-filter-container {
   display: flex;
   gap: 1em;
   // border: 1px solid rgb(160, 160, 160);
   // border-radius: 5px;
   padding: 1em 2em;
}
@media (max-width: 1400px) {
   #dashboard-title {
      margin-bottom: 0.5em;
   }
   .graph-card {
      width: 100%;
   }
   #kpi-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
   #graphs-container {
      flex-direction: column;
   }
   #dropdown-container {
      margin-bottom: 1em;
   }
   #chips-container {
      width: 100%;
   }
}
#home-main-container {
   min-height: 100vh;
}

@media (max-width: 1200px) {
   #code-snippets-container {
      display: flex;
      flex-direction: column;
   }
   #code-snippets {
      display: none;
   }
   #intro-paragraph {
      display: none;
   }
   #project-id {
      display: none;
   }
}
@media (min-width: 1200px) {
   #mobile-install-instructions {
      display: none;
   }
   #installation-support-card {
      max-width: 600px;
   }
}
#code-snippets-container {
   margin-top: 2em;
   display: flex;
   gap: 20px;
}
#intro-paragraph {
   max-width: 800px;
}
#installation-support-card {
   width: 100%;
}
#optimized-vs-default-graph-controls {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
}
#conversion-rate-graph-controls {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   gap: 2em;
}
#traffic-distribution-graph-controls {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
}
</style>
