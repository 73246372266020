<template>
   <div>
      <CodeEditor :code="code" :languages="['html']" />
   </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue"
import CodeEditor from "../../../EzbotCodeEditor.vue"
import { useVisualEditorStore } from "../../../../stores/visualEditorStore"

const store = useVisualEditorStore()
const code = ref(store.selectedElement?.outerHTML || "")
watch(
   () => store.selectedElement,
   () => {
      code.value = store.selectedElement?.outerHTML || ""
   },
)
</script>
<style lang="scss">
h5 {
   font-size: 1.2em;
   margin-bottom: 0;
   margin-top: 0;
}
#selector-container {
   font-weight: bold;
   margin-bottom: 1rem;

   #selector {
      margin-top: 0;
   }
}
</style>
../../../stores/visualEditorStore
