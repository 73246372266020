import {
   BaseBasicEditorVariable,
   BasicEditorVariable,
} from "../../../types/visual-editor"
import { v4 as uuidv4 } from "uuid"
import { useVisualEditorStore } from "../../../stores/visualEditorStore"
import { DBVariable } from "../../../types"

const numVariations = (variable: BasicEditorVariable) => {
   const defaultValue = variable.defaultValue ? 1 : 0
   const variations = variable.allowedValues ? variable.allowedValues.length : 0
   return defaultValue + variations
}

const buttonClasses = (variable: BasicEditorVariable) => {
   return {
      "bg-purple-4": variable.selected,
      "bg-purple-7": !variable.selected,
      "text-white": true,
      "basic-editor-action": true,
   }
}

const badgeColor = (num: number) => {
   if (num == 0) {
      return "red"
   }
   if (num < 3) {
      return "orange"
   } else {
      return "green"
   }
}

const prepareVariablesForCreateAndUpdate = (
   baseVariables: BaseBasicEditorVariable[],
   variables: DBVariable[],
   selectedVariable: BasicEditorVariable | null,
): BasicEditorVariable[] => {
   const visualEditorStore = useVisualEditorStore()
   return baseVariables.map((category) => {
      // Find visual variable
      const match = variables.find((variable) => {
         return (
            variable.config?.action == category.action &&
            variable.config?.selector ==
               visualEditorStore.selectedElement?.selector
         )
      })
      if (!match) {
         return {
            label: category.label,
            action: category.action,
            selected: selectedVariable?.action == category.action,
         } as BasicEditorVariable
      }
      const variable: BasicEditorVariable = {
         id: null,
         label: category.label,
         action: category.action,
         defaultValue: match.defaultValue,
         selector: match.config!.selector,
         allowedValues: match.constraints.enumerables,
         selected: selectedVariable?.action == category.action,
         color: selectedVariable?.color,
      }

      return variable
   })
}

const getAllowedAndDefaultValues = (
   variable: BasicEditorVariable,
): Array<string> => {
   if (!variable.defaultValue) {
      return []
   }
   return variable.allowedValues
      ? [variable.defaultValue!, ...variable.allowedValues!]
      : [variable.defaultValue!]
}

const buildKey = () => {
   return uuidv4()
}

const buildHumanReadableName = (selector: string, action: string) => {
   return `${selector} - ${action}`
}

export {
   numVariations,
   badgeColor,
   prepareVariablesForCreateAndUpdate,
   buttonClasses,
   getAllowedAndDefaultValues,
   buildKey,
   buildHumanReadableName,
}
