<template>
   <SignUpEnabled v-if="signUpEnabled"></SignUpEnabled>
   <SignUpDisabled v-else></SignUpDisabled>
</template>

<script lang="ts" setup>
import { useHead } from "@unhead/vue"
import SignUpDisabled from "./SignUpDisabled.vue"
import SignUpEnabled from "./SignUpEnabled.vue"
import { onMounted, ref } from "vue"

const signUpEnabled = ref(false)

onMounted(() => {
   const urlParams = new URLSearchParams(window.location.search)
   const clerkTicket = urlParams.get("__clerk_ticket")
   if (clerkTicket) {
      signUpEnabled.value = true
   }
})

useHead({
   title: "Sign Up - ezbot.ai",
   meta: [
      {
         name: "description",
         content: "Sign up for ezbot.ai with a free trial",
      },
      {
         property: "og:title",
         content: "Sign Up - ezbot.ai",
      },
      {
         property: "og:description",
         content: "Sign up for ezbot.ai with a free trial",
      },
      {
         property: "og:url",
         content: "https://app.ezbot.ai/sign-up",
      },
      {
         property: "twitter:title",
         content: "Sign Up - ezbot.ai",
      },
      {
         property: "twitter:description",
         content: "Sign up for ezbot.ai with a free trial",
      },
      {
         property: "twitter:url",
         content: "https://app.ezbot.ai/sign-up",
      },
   ],
   link: [
      {
         rel: "canonical",
         href: "https://app.ezbot.ai/sign-up",
      },
   ],
})
</script>
