<template>
   <div
      id="main-container"
      class="q-pa-sm bg-white"
      style="position: sticky; top: 74px; z-index: 999"
   >
      <Badge
         icon="mdi-account-multiple"
         prefix="Sessions"
         :first-number="additionalSessions"
         :second-number="cumulativeSessions"
         tooltip-text="The left number represents the number of additional sessions observed in the selected time window. The right number represents the cumulative number of sessions observed throughout this project version."
      />
      <Badge
         icon="mdi-head-check"
         prefix="Additional Conversions"
         :first-number="additionalConversions"
         :second-number="cumulativeConversions"
         tooltip-text="The left number represents the number of additional sessions with at least one conversion observed in the selected time window. The right number represents the cumulative number of sessions with at least one conversion observed throughout this project version."
      />
      <AggLevelSelector />
      <DatePickerSelector2 />
   </div>
</template>
<script setup lang="ts">
import { AggLevelSelector } from "@components/index"
import DatePickerSelector2 from "@components/dashboard/DatePickerSelector2.vue"
import Badge from "./Badge.vue"
import { useDashboardStore } from "@stores/dashboardStore"
import { computed } from "vue"
const dashboardStore = useDashboardStore()
const additionalConversions = computed(() => {
   const raw = dashboardStore.stats?.addl_conversions
   return Math.round(raw!)
})
const cumulativeConversions = computed(() => {
   const raw = dashboardStore.stats?.total_addl_conversions
   return Math.round(raw!)
})
const additionalSessions = computed(() => {
   const raw = dashboardStore.stats?.sessions
   return Math.round(raw!)
})
const cumulativeSessions = computed(() => {
   const raw = dashboardStore.stats?.total_sessions
   return Math.round(raw!)
})
</script>
<style scoped lang="scss">
#main-container {
   display: flex;
   justify-content: end;
   gap: 1em;
   padding: 20px;
   border: 1px solid #ccc;
}
@media (min-width: 768px) {
   #main-container {
      flex-direction: row;
   }
   #kpi-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      max-width: 1200px;
   }
}
</style>
