<template>
   <div id="onboarding-container" class="q-pa-md">
      <CreateOrganization
         after-create-organization-url="/onboarding/check-subscription"
         path="/onboarding/create-org"
         routing="path"
      />
   </div>
</template>
<script setup lang="ts">
import { CreateOrganization } from "vue-clerk"
import { onMounted } from "vue"
import { getUser } from "../../services/clerk"
import { trackEvent } from "@/analytics/trackEvent"

onMounted(async () => {
   const user = await getUser()
   if (user?.id) {
      const emailAddress = user.email
      if (emailAddress) {
         window.rewardful("convert", { email: emailAddress })
         trackEvent("rewardful_conversion")
      } else {
         console.error(
            "User email address not found. Did not send rewardful conversion event.",
         )
         return
      }
   } else {
      console.error("User not found. Did not send rewardful conversion event.")
      return
   }

   console.log("Called Rewardful to track conversion.")
})
</script>
<style lang="scss" scoped>
#onboarding-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100%;
   background-image: linear-gradient(to top right, #007bff, #00aaff);
}
</style>
