<template>
   <div>
      <h5 class="text-left">Allowed Values</h5>
      <ul id="allowed-values-instructions" class="text-left">
         <li>Must have at least one allowed value and one default value.</li>
         <li>Default values are indicated by a check mark.</li>
         <li>Click on a value to set it as the default.</li>
      </ul>
      <template v-if="error">
         <p id="error-message">
            {{ errorMessage }}
         </p>
      </template>
      <div id="chip-container" ref="chipContainer" :class="{ error: error }">
         <template
            v-for="allowedValue in allowedValues"
            v-bind:key="allowedValue"
         >
            <q-chip
               removable
               clickable
               v-model:selected="allowedValue.default"
               @click="setDefaultValue(allowedValue)"
               @remove="removeAllowedValue(allowedValue)"
               color="primary"
               size="md"
               text-color="white"
            >
               {{ allowedValue.value }}
            </q-chip>
         </template>
      </div>
      <div id="add-allowed-value">
         <q-input
            ref="newAllowedValueInput"
            v-model="newAllowedValue"
            label="Add Allowed Value"
            lazy-rules
            :color="error ? 'negative' : 'primary'"
            outlined
            :rules="[validateNewAllowedValue]"
            @blur="onBlur"
            @keyup.enter.prevent="addAllowedValue"
         >
            <template v-slot:append>
               <q-icon
                  name="mdi-plus-box"
                  @click="addAllowedValue"
                  class="cursor-pointer q-ml-lg"
                  color="primary"
               />
            </template>
         </q-input>
      </div>
   </div>
</template>
<script setup lang="ts">
import { QInput, QIcon, QChip } from "quasar"
import { computed, ref, Ref, VNodeRef } from "vue"
import { ValueChoice } from "../types/variable"

const $emit = defineEmits([
   "added-allowed-value",
   "removed-allowed-value",
   "set-default-value",
])
const props = defineProps({
   allowedValues: {
      type: Array,
      default: () => [],
   },
   allowedValueType: {
      type: String,
      required: true,
   },
})
const error = ref(false)
const newAllowedValueInput: VNodeRef = ref(null)
const chipContainer: VNodeRef = ref(null)
const allowedValues: Ref<Array<ValueChoice>> = ref(
   props.allowedValues as Array<ValueChoice>,
)
const resetValidation = () => {
   newAllowedValue.value = ""
   newAllowedValueInput.value.resetValidation()
}
const updateAllowedValues = (values: Array<ValueChoice>) => {
   allowedValues.value = values
}
const validate = () => {
   if (allowedValues.value.length < 2) {
      newAllowedValueInput.value.focus()
      error.value = true
      return false
   }
   if (newAllowedValue.value.length > 1) {
      newAllowedValueInput.value.focus()
      error.value = true
      return false
   }
   if (!allowedValues.value.some((allowedValue) => allowedValue.default)) {
      newAllowedValueInput.value.focus()
      error.value = true
      return false
   }
   error.value = false
   return newAllowedValueInput.value.validate()
}
// Expose to parent component
defineExpose({
   validate,
   updateAllowedValues,
   resetValidation,
})

const newAllowedValue = ref("")

const validateStyle = (val: string): string | boolean => {
   // TODO: maybe remove this and don't validate the field on submit.
   if (!val) {
      return true
   }
   // TODO: FIX ME
   // match: "font-size: 12px;"
   // match: "font-size: 12px; color: red;"
   // if (!val.match(/^([a-z-]+:\s[a-z0-9]+;\s?)+$/)) {
   //    return "Must be a valid CSS style"
   // }

   return true
}
const validateHTML = (val: string): string | boolean => {
   if (!val) {
      return true
   }
   // todo: FIX ME
   // if (!val.match(/^[a-zA-Z0-9_#.[\]:"'= <>/]+$/)) {
   //    return "Must be a valid HTML"
   // }
   return true
}
const validateURL = (val: string): string | boolean => {
   if (!val) {
      return true
   }
   if (!val.match(/^(http|https):\/\/[^ "]+$/)) {
      return "Must be a valid URL"
   }
   return true
}

const validateAttribute = (val: string): string | boolean => {
   const errorMessage =
      "Must be in kabob-case like 'data-load', 'href, or 'my-attribute'"
   if (!val) {
      return true
   }
   if (val && !val.match(/^[a-z0-9-]+$/)) {
      return errorMessage
   }

   return true
}

const addAllowedValue = () => {
   if (!newAllowedValueInput.value.validate()) {
      return
   }
   const defaultBool = allowedValues.value.length == 0
   allowedValues.value = [
      ...allowedValues.value,
      { default: defaultBool, value: newAllowedValue.value },
   ]
   $emit("added-allowed-value", allowedValues.value)
   newAllowedValue.value = ""
   error.value = false
}

const removeAllowedValue = (value: ValueChoice) => {
   newAllowedValueInput.value.resetValidation()
   allowedValues.value = allowedValues.value.filter(
      (allowedValue) => allowedValue !== value,
   )
   $emit("removed-allowed-value", allowedValues.value)
}

const setDefaultValue = (value: ValueChoice) => {
   allowedValues.value = allowedValues.value.map((allowedValue) => {
      if (allowedValue === value) {
         allowedValue.default = true
      } else {
         allowedValue.default = false
      }
      return allowedValue
   })
   $emit("set-default-value", allowedValues.value)
}

const errorMessage = computed(() => {
   if (newAllowedValue.value.length > 1) {
      return "Please add the value by clicking the '+' button"
   }
   const defaultChosen = allowedValues.value.some(
      (allowedValue) => allowedValue.default,
   )
   if (allowedValues.value.length == 0) {
      return "You must specify at least one allowed value and one default value"
   }
   if (defaultChosen && allowedValues.value.length == 1) {
      return "You must specify at least one more allowed value"
   }
   if (!defaultChosen) {
      return "Default value missing. Click on a value to set it as the default"
   }
   return ""
})

const validateNewAllowedValue = (val: string): string | boolean => {
   if (
      allowedValues.value.some(
         (allowedValue) => allowedValue.value === newAllowedValue.value,
      )
   ) {
      return "Value already exists"
   }
   switch (props.allowedValueType) {
      case "string":
         return true
      case "html":
         return validateHTML(val)
      case "style":
         return validateStyle(val)
      case "attribute":
         return validateAttribute(val)
      case "url":
         return validateURL(val)
      case "css":
         return true // TODO: validate css
   }
   return true
}
const onBlur = () => {
   newAllowedValueInput.value.resetValidation()
}
</script>
<style scoped lang="scss">
h5 {
   margin-top: 20px;
   margin-bottom: 10px;
}
#chip-container {
   display: flex;
   flex-wrap: wrap;
   gap: 5px;
   min-height: 100px;
   width: 100%;
   outline: 1px dotted $primary;
   margin: 10px 0;
   padding: 5px;
   &.error {
      outline: 1px dotted $negative;
   }
}
#add-allowed-value {
   width: 350px;
}
#allowed-values-instructions {
   padding-inline-start: 15px;
}
#error-message {
   font-weight: bold;
   color: $negative;
   text-align: left;
}
</style>
