<template>
   <QCard class="kpi-card q-pa-sm">
      <QCardSection>
         <QIcon
            class="kpi-icon"
            :name="$props.icon"
            size="3em"
            :color="cardColor"
         />
         <div class="text-h6">{{ $props.title }}</div>
         <QSeparator />
         <div class="text-h5 kpi-number">
            <div
               v-if="$props.number && $props.number > 0"
               :class="`color-${cardColor}`"
            >
               <CountUp :end-val="$props.number" :options="countUpOptions">
               </CountUp>
            </div>
            <div v-else-if="$props.number" :class="`color-${cardColor}`">
               <span id="still-learning">Still learning</span>
            </div>
            <div v-else>
               <span :class="`color-${cardColor}`" id="metric-coming-soon"
                  >Metric coming soon</span
               >
            </div>
            <!-- <div
               v-if="$props.percentChange != 0"
               :class="`kpi-percent-change color-${cardColor}`"
            >
               {{
                  $props.percentChange && $props.percentChange > 0 ? "+" : "-"
               }}
               {{ $props.percentChange }}%
            </div>
            <div v-else><span>&nbsp;</span></div> -->
         </div>
         <div class="text-caption kpi-subtitle">
            {{ $props.subtitle }}
         </div>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { computed } from "vue"
import CountUp from "vue-countup-v3"
const props = defineProps({
   title: {
      type: String,
      required: true,
   },
   color: {
      type: String,
      required: false,
      default: "neutral",
   },
   icon: {
      type: String,
      required: true,
   },
   number: {
      type: Number,
      required: false,
   },
   percentChange: {
      type: Number,
      required: false,
      default: 0,
   },
   subtitle: {
      type: String,
      required: true,
   },
   showChange: {
      type: Boolean,
      required: false,
      default: true,
   },
   numberUnits: {
      type: String,
      required: false,
      default: "none",
   },
})
const cardColor = computed(() => {
   if (props.color === "neutral") {
      return "primary"
   } else if (props.color === "green") {
      return "positive"
   } else {
      return "primary"
   }
})
const baseCountUpOptions = {
   duration: 2,
   useEasing: true,
   useGrouping: true,
   separator: ",",
}
const positiveDollarCountUpOptions = {
   ...baseCountUpOptions,
   prefix: "+ $",
   decimalPlaces: 2,
}
const negativeDollarCountUpOptions = {
   ...baseCountUpOptions,
   prefix: "- $",
   decimalPlaces: 2,
}
const neutralDollarCountUpOptions = {
   ...baseCountUpOptions,
   prefix: "$",
   decimalPlaces: 2,
}
const positiveBaseCountUpOptions = {
   ...baseCountUpOptions,
   prefix: "+ ",
}
const negativeBaseCountUpOptions = {
   ...baseCountUpOptions,
   prefix: "- ",
}
const countUpOptions = computed(() => {
   if (props.numberUnits === "dollars") {
      if (props.percentChange > 0) {
         return positiveDollarCountUpOptions
      } else if (props.percentChange < 0) {
         return negativeDollarCountUpOptions
      } else {
         return neutralDollarCountUpOptions
      }
   } else {
      if (props.percentChange > 0) {
         return positiveBaseCountUpOptions
      } else if (props.percentChange < 0) {
         return negativeBaseCountUpOptions
      } else {
         return baseCountUpOptions
      }
   }
})
</script>
<style scoped lang="scss">
.text-h6 {
   padding: 0.25em;
}
.color-positive {
   color: $positive;
}
.color-negative {
   color: $negative;
}
.kpi-number {
   margin: 0.5em;
   font-weight: bold;
}
.kpi-subtitle {
   font-style: italic;
}
.kpi-percent-change {
   font-size: 0.6em;
   font-weight: bold;
   font-style: italic;
}
#metric-coming-soon {
   font-style: italic;
   font-weight: normal;
   font-size: 0.75em;
}
</style>
