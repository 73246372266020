const logError = (error: Error) => {
   console.error(error)
}

const logInfo = (message: string, object?: unknown) => {
   if (object) {
      console.info(`[ezbot UI] ${message}`, object)
      return
   }
   console.info(`[ezbot UI] ${message}`)
}

export { logError, logInfo }
