<template>
   <div>
      <QSelect
         v-if="options.length > 0"
         v-model="selectedLabelValuePair"
         :options="options"
         bg-color="white"
         filled
         fill-input
         standout
         class="q-ma-sm project-picker"
      ></QSelect>
   </div>
</template>

<script setup lang="ts">
import { QSelect } from "quasar"
import { computed, ref, watch, Ref } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { useRouter, useRoute } from "vue-router"
import { getOrg } from "@/services/clerk"
import { useDashboardStore } from "@/stores"

const projectStore = useProjectStore()
const dashboardStore = useDashboardStore()
const router = useRouter()

const route = useRoute()

type LabelValuePair = {
   label: string
   projectId?: number
}

const options = computed(() => {
   const labelValuePairs = projectStore.projects.map((project) => {
      return {
         label: project.humanReadableName,
         projectId: project.id,
      }
   })
   return [...labelValuePairs, CreateProjectPair] as LabelValuePair[]
})

const SelectProjectPair = {
   label: "Select Project",
}
const CreateProjectPair = {
   label: "Create Project",
}
const selectedLabelValuePair: Ref<LabelValuePair> = ref(SelectProjectPair)

let match = options.value.find(
   (option) => option.projectId === Number(route.params.projectId),
)

if (match) {
   selectedLabelValuePair.value = match as LabelValuePair
}

const org = await getOrg()
if (!org) {
   throw new Error("Org is undefined")
}
const { slug } = org
// // when selected project changes, navigate to the project
watch(selectedLabelValuePair, () => {
   if (!selectedLabelValuePair.value) {
      throw new Error("Selected project is undefined")
   }

   if (!selectedLabelValuePair.value.projectId) {
      return router.push({
         name: "CreateProject",
         params: { orgSlug: slug },
      })
   }

   router
      .push({
         name: "ProjectHome",
         params: {
            orgSlug: slug,
            projectId: selectedLabelValuePair.value.projectId,
         },
      })
      .then(() => {
         // TODO: should probably set this automatically somewhere else
         const newProjectId = selectedLabelValuePair.value.projectId
         if (!newProjectId) {
            throw new Error("New project id is undefined")
         }
         projectStore.projectId = newProjectId
         projectStore.getProjectSettings(newProjectId)
         dashboardStore.clearState()
      })
   return
})

const currentProjectId: Ref<string> = ref(String(route.params.projectId))

// when route changes, display the project from the route
watch(
   () => route.params.projectId,
   () => {
      if (route.name === "CreateProject") {
         selectedLabelValuePair.value = CreateProjectPair
         projectStore.projectId = null
         router.push({
            name: "CreateProject",
            params: { orgSlug: slug },
         })
         return
      }
      if (route.params.projectId != currentProjectId.value) {
         currentProjectId.value = String(route.params.projectId)
         const match = options.value.find(
            (option) => option.projectId === Number(route.params.projectId),
         )
         if (match) {
            selectedLabelValuePair.value = match
         }
      }
   },
)
</script>
<style lang="scss">
.project-picker {
   .q-field__control {
      border-radius: 4px;
   }
}
</style>
