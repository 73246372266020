<template>
   <div id="sign-up-container">
      <QCard id="book-a-demo">
         <div class="bg-green" id="book-a-demo-header-text">
            Ready to get started?
         </div>
         <QCardSection id="book-a-demo-main-text" class="text-left">
            <p id="description" class="text-left">
               Click the button below to book a demo with us.
            </p>
            <QBtn
               id="book-a-demo-button"
               size="lg"
               class="full-width text-bold"
               color="primary"
               push
               href="https://share.hsforms.com/1I0YA0vzaTj69zmgJ-p0o_Aqht4f"
               target="_blank"
               >Book a Demo</QBtn
            >
         </QCardSection>
      </QCard>
   </div>
</template>

<script lang="ts" setup>
import { useHead } from "@unhead/vue"
useHead({
   title: "Book a Demo - ezbot.ai",
   meta: [
      {
         name: "description",
         content: "Book a demo with ezbot.ai",
      },
      {
         property: "og:title",
         content: "Book a Demo - ezbot.ai",
      },
      {
         property: "og:description",
         content: "Book a demo with ezbot.ai",
      },
      {
         property: "og:url",
         content: "https://app.ezbot.ai/sign-up",
      },
      {
         property: "twitter:title",
         content: "Book a Demo - ezbot.ai",
      },
      {
         property: "twitter:description",
         content: "Book a demo with ezbot.ai",
      },
      {
         property: "twitter:url",
         content: "https://app.ezbot.ai/sign-up",
      },
   ],
   link: [
      {
         rel: "canonical",
         href: "https://app.ezbot.ai/sign-up",
      },
   ],
})
</script>

<style lang="scss" scoped>
#sign-up-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 20px;
   align-items: center;
   min-height: 100vh;
   padding: 1.5em;
   // background-image: linear-gradient(
   //    to top right,
   //    #7e00f7 5%,
   //    #c451af 25%,
   //    #007bff,
   //    #00aaff
   // );
   background-image: linear-gradient(to top right, #007bff, #00aaff);
}

#book-a-demo {
   width: 100%;
   max-width: 400px;
   background-color: white;
   border-radius: 10px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   ol {
      margin-top: 0.5em;
      padding-inline-start: 15px;
   }
}

#book-a-demo-header-text {
   color: white;
   font-weight: bold;
   font-size: 1.5rem;
   letter-spacing: -1px;
   padding: 0.5em;
}
#remind-me-later-container {
   display: flex;
   gap: 20px;
   justify-content: center;
   margin-top: 1em;
}
#next-card-reference-mobile {
   display: inline;
}
#next-card-reference-desktop {
   display: none;
}
#you-need {
   margin-bottom: 0;
}
#to-get-started {
   margin-top: 1em;
}
#description {
   margin-bottom: 2.5em;
}
@media (min-width: 768px) {
   #book-a-demo-header-text {
      font-size: 1.75rem;
      letter-spacing: 0px;
   }
   #next-card-reference-mobile {
      display: none;
   }
   #next-card-reference-desktop {
      display: inline;
   }
}
@media (min-width: 1024px) {
   #book-a-demo {
      max-width: 600px;
   }
   #book-a-demo-main-text {
      padding: 2em;
   }
   #remind-me-later-container {
      display: flex;
      gap: 20px;
      justify-content: start;
      margin-top: 1em;
   }
}
</style>
