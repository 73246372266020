<template>
   <QCard>
      <QCardSection class="bg-red text-white">
         <div class="text-h6">Subscription Error</div>
      </QCardSection>
      <QCardSection>
         <div>
            There was an error validating your subscription. Please contact
            <a href="mailto:support@ezbot.ai">support@ezbot.ai</a>
         </div>
      </QCardSection>
   </QCard>
</template>

<script>
export default {
   name: "SubscriptionError",
}
</script>

<style scoped>
.bg-red {
   background-color: #f44336;
}
</style>
