<template>
   <template v-for="variable in variableOptions" v-bind:key="variable.label">
      <q-btn
         :class="buttonClasses(variable)"
         @click="handleActionClick(variable)"
         push
         :label="variable.label"
      >
         <q-badge
            rounded
            v-if="numVariations(variable) === 0"
            :color="badgeColor(numVariations(variable))"
            floating
         ></q-badge>
         <q-badge
            v-else
            :color="badgeColor(numVariations(variable))"
            floating
            >{{ numVariations(variable) }}</q-badge
         >
      </q-btn>
   </template>
</template>
<script setup lang="ts">
import { QBtn, QBadge } from "quasar"
import { Ref, computed } from "vue"
import {
   BaseBasicEditorVariable,
   BasicEditorVariable,
} from "../../../../types/visual-editor"
import {
   numVariations,
   badgeColor,
   buttonClasses,
   prepareVariablesForCreateAndUpdate,
} from "../shared"
import { useProjectStore } from "../../../../stores"
import { useVisualEditorStore } from "../../../../stores/visualEditorStore"

const projectStore = useProjectStore()
const visualEditorStore = useVisualEditorStore()

const baseVariables = [
   {
      label: "Text",
      action: "setText",
   },
   {
      label: "Font Size",
      action: "setFontSize",
   },
   {
      label: "Font Color",
      action: "setFontColor",
   },
   {
      label: "Bkg Color",
      action: "setBackgroundColor",
   },
   {
      label: "Visibility",
      action: "setVisibility",
   },
] as BaseBasicEditorVariable[]

// Enrich the base variables with defaultValues, allowedValues, and selected
const variableOptions: Ref<BasicEditorVariable[]> = computed(() => {
   return prepareVariablesForCreateAndUpdate(
      baseVariables,
      projectStore.variables,
      visualEditorStore.activeVariable,
   ) as BasicEditorVariable[]
})

const handleActionClick = (variable: BasicEditorVariable) => {
   visualEditorStore.selectVariable(variable)
}
</script>
<style lang="scss" scoped>
.basic-editor-action {
   margin: 5px;
}
</style>
