<template>
   <div>
      <div id="basic-editor-actions-container">
         <TextButtonSet v-if="isTextTag" />
         <ImgButtonSet v-else-if="isImgTag" />
         <AnchorButtonSet v-else-if="isAnchorTag" />
         <DefaultButtonSet v-else />
         <OuterHTMLButton
            v-if="visualEditorStore.matchingVariableWithOuterHTMLAction"
         />
      </div>
      <div>
         <TextVariationsEditor v-if="openTextVariationEditor" />
         <ColorVariationsEditor v-else-if="openColorVariationEditor" />
         <VisibilityVariationsEditor
            v-else-if="
               visualEditorStore.activeVariable?.action === 'setVisibility'
            "
         />
         <ImgSrcVariationsEditor v-else-if="openImgSrcVariationEditor" />
      </div>
   </div>
</template>
<script setup lang="ts">
import TextButtonSet from "./button-sets/TextButtonSet.vue"
import ImgButtonSet from "./button-sets/ImgButtonSet.vue"
import AnchorButtonSet from "./button-sets/AnchorButtonSet.vue"
import DefaultButtonSet from "./button-sets/DefaultButtonSet.vue"
import OuterHTMLButton from "./button-sets/OuterHTMLButton.vue"
import TextVariationsEditor from "./variation-editors/TextVariationsEditor.vue"
import ColorVariationsEditor from "./variation-editors/ColorVariationsEditor.vue"
import VisibilityVariationsEditor from "./variation-editors/VisibilityVariationsEditor.vue"
import ImgSrcVariationsEditor from "./variation-editors/ImgSrcVariationsEditor.vue"
import { useVisualEditorStore } from "@/stores"
import { computed } from "vue"
import { useProjectStore } from "../../../stores"
const visualEditorStore = useVisualEditorStore()
const projectStore = useProjectStore()
await projectStore.getVariablesByProjectId()
visualEditorStore.sendVariablesToSDK()
const tag = computed(() => {
   return visualEditorStore.selectedElement?.tag
})
const textTags = [
   "h1",
   "h2",
   "h3",
   "h4",
   "h5",
   "h6",
   "p",
   "span",
   "a",
   "button",
   "label",
   "li",
   "td",
]
const isTextTag = computed(() => {
   if (tag.value === undefined) return false
   const lowerCaseTag = tag.value.toLowerCase()
   return textTags.includes(lowerCaseTag)
})
const isImgTag = computed(() => {
   if (tag.value === undefined) return false
   const lowerCaseTag = tag.value.toLowerCase()
   return lowerCaseTag === "img"
})
const isAnchorTag = computed(() => {
   if (tag.value === undefined) return false
   const lowerCaseTag = tag.value.toLowerCase()
   return lowerCaseTag === "a"
})

const textActions = ["setText", "setFontSize"]
const openTextVariationEditor = computed(() => {
   const activeVariable = visualEditorStore.activeVariable
   if (!activeVariable) return false
   return textActions.includes(activeVariable.action)
})
const colorActions = ["setFontColor", "setBackgroundColor"]
const openColorVariationEditor = computed(() => {
   const activeVariable = visualEditorStore.activeVariable
   if (!activeVariable) return false
   return colorActions.includes(activeVariable.action)
})
const srcActions = ["setSrc"]
const openImgSrcVariationEditor = computed(() => {
   const activeVariable = visualEditorStore.activeVariable
   if (!activeVariable) return false
   return srcActions.includes(activeVariable.action)
})
</script>
<style lang="scss" scoped>
#basic-editor-actions-container {
   display: flex;
   flex-wrap: wrap;
   margin-top: 20px;
}
</style>
