<template>
   <div class="bg-white q-pa-md">
      <h1 class="text-left text-h4">Create Attribute</h1>
      <p class="text-left">
         Attributes help ezbot personalize user experiences. You can
         <a
            href="https://docs.ezbot.ai/reference-materials/glossary/"
            target="_blank"
         >
            read more about them here.</a
         >
      </p>
      <QForm id="attribute-edit-form" @submit="handleSubmit">
         <q-input
            v-model="humanReadableName"
            filled
            label="Human Readable Name"
            lazy-rules
            :rules="[
               (val) => (val && val.length > 0) || 'Please type something',
            ]"
         />
         <q-input
            v-model="key"
            filled
            label="Attribute Key"
            lazy-rules
            :rules="[validateKey]"
         />
         <div class="row justify-end">
            <QBtn id="submit" type="submit" color="primary">Submit</QBtn>
         </div>
      </QForm>
   </div>
</template>
<script setup lang="ts">
import { QForm, QInput, QBtn } from "quasar"
import { ref } from "vue"
import { useProjectStore } from "../stores/projectStore"
import { useRouter } from "vue-router"
import { toast } from "vue3-toastify"

const projectStore = useProjectStore()
const router = useRouter()
const blankAttribute = {
   humanReadableName: "",
   key: "",
}
const humanReadableName = ref(blankAttribute.humanReadableName)
const key = ref(blankAttribute.key)

const handleSubmit = async () => {
   if (!projectStore.projectId) {
      throw new Error("No project ID found")
   }
   await projectStore.createAttribute({
      humanReadableName: humanReadableName.value,
      key: key.value,
      projectId: projectStore.projectId,
   })
   router
      .push({
         name: "Attributes",
         params: { projectId: projectStore.projectId },
      })
      .then(() => {
         toast.success("Attribute created.")
      })
}

const validateKey = (val: string): string | boolean => {
   if (!val) {
      return "A valid snake case key is required"
   }
   if (!val.match(/^[a-z0-9_]+$/)) {
      return "Must be a valid snake case string like 'my_attribute_key'"
   }
   return true
}
</script>
<style lang="scss">
h3 {
   margin: 0;
}
.chip-container {
   display: flex;
   align-items: left;
   margin-bottom: 1em;
}
#attribute-id-chip {
   margin-left: 0;
}
#created-by-chip {
   margin-left: 0;
}
#attribute-edit-form {
   border: 1px dotted darkgrey;
   padding: 1em;
   margin-bottom: 1em;
}
#attribute-edit-form {
   width: 100%;
   max-width: 600px;
}
.q-field {
   margin-bottom: 10px;
}
</style>
