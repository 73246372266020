<template>
   <Suspense>
      <div>
         <component :is="this.$route.meta.layoutComponent">
            <slot />
         </component>
      </div>
   </Suspense>
</template>

<script>
export default {
   name: "AppLayout",
}
</script>
