<template>
   <div>
      <QTable
         :rows="projectStore.variables"
         :columns="columns"
         row-key="name"
         :rows-per-page-options="[0]"
         :pagination="{
            page: 1,
            rowsPerPage: 0, // 0 means all rows
            sortBy: 'updatedAt',
            descending: true,
         }"
         title="Variables"
         no-data-label="No variables found."
         hide-bottom
         @row-click="onRowClick"
      >
         <template v-slot:body-cell-type="props: unknown">
            <q-td :props="props">
               <q-chip
                  v-if="(props as QTablePropPatch).value == 'basic'"
                  color="blue"
                  size="md"
                  text-color="white"
                  :label="_.capitalize((props as QTablePropPatch).value)"
               />
               <q-chip
                  v-else
                  color="purple"
                  text-color="white"
                  :label="_.capitalize((props as QTablePropPatch).value)"
               />
            </q-td>
         </template>
      </QTable>
      <div id="action-container" class="row justify-end">
         <QBtn
            id="create-variable"
            color="primary"
            label="Create Variable"
            @click="handleCreateVariable"
         >
         </QBtn>
      </div>
   </div>
</template>

<script setup lang="ts">
import { QTable, QBtn, QChip, QTd, QTableProps } from "quasar"
import { useRouter } from "vue-router"
import { format } from "timeago.js"
import { useProjectStore } from "../stores"
import { QTableColumn } from "quasar"
import _ from "lodash"

interface Variable {
   humanReadableName: string
   type: string
   id: number
   key: string
   createdBy: number | string
   createdAt: string
   updatedAt: string
}

type VariableRow = {
   [K in keyof Variable]: Variable[K]
}
type QTablePropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & { value: string }

const router = useRouter()
const projectStore = useProjectStore()
if (projectStore.projectId == null) {
   throw new Error("Cannot load Variables page without a projectId.")
}
await projectStore.getVariablesByProjectId()

const columns: QTableColumn<Variable>[] = [
   {
      name: "type",
      align: "left",
      label: "Type",
      field: (row: Variable) => row.type,
      sortable: true,
   },
   {
      name: "humanReadableName",
      align: "left",
      label: "Human Readable Name",
      field: (row: Variable) => row.humanReadableName,
      sortable: true,
   },
   {
      name: "id",
      required: true,
      label: "Variable Id",
      align: "center",
      field: (row: Variable) => row.id,
      sortable: true,
   },
   {
      name: "key",
      align: "center",
      label: "Variable Key",
      field: (row: Variable) => row.key,
      sortable: true,
   },
   {
      name: "createdBy",
      align: "center",
      label: "Created By",
      field: (row: Variable) => {
         if (row.createdBy == 1) {
            return "You"
         } else {
            return row.createdBy
         }
      },
      sortable: true,
   },
   {
      name: "createdAt",
      align: "center",
      label: "Created",
      field: (row: Variable) => row.createdAt,
      format: (val: Date) => format(val),
      sortable: true,
   },
   {
      name: "updatedAt",
      align: "center",
      label: "Updated",
      field: (row: Variable) => row.updatedAt,
      format: (val: Date) => format(val),
      sortable: true,
   },
]

const onRowClick = (event: Event, row: VariableRow) => {
   router.push({ name: "EditVariable", params: { variableId: row.id } })
}

const handleCreateVariable = () => {
   router.push({ name: "CreateVariable" })
}
</script>
<style lang="scss">
#action-container {
   margin-top: 1em;
}
</style>
