<template>
   <div id="stat-sig-renderer">
      <template v-if="props.val.lb != 0 && props.val.ub != 0">
         <div id="lb-number">
            {{ formatPercentTwoDecimals(props.val.lb) }}
         </div>
         <div id="lb-error" :style="{ width: lbErrWidth + 'px' }"></div>
         <div id="ub-error" :style="{ width: ubErrWidth + 'px' }"></div>
         <div id="ub-number">
            {{ formatPercentTwoDecimals(props.val.ub) }}
         </div>
      </template>
      <template v-else> Insufficient Data </template>
   </div>
</template>
<script setup lang="ts">
import { PropType, computed } from "vue"
import { StatSigForRender } from "../../types/combinations-table"
import { formatPercentTwoDecimals } from "./formatters"

const props = defineProps({
   val: {
      type: Object as PropType<StatSigForRender>,
      required: true,
   },
})
const fullWidth = 120
const lengthOfRange = computed(() => {
   return props.val.ub - props.val.lb
})
const lbErrWidth = computed(() => {
   if (props.val.lb < props.val.defaultConversionRate) {
      const width =
         ((props.val.defaultConversionRate - props.val.lb) /
            lengthOfRange.value) *
         fullWidth
      return Math.min(width, fullWidth)
   } else {
      return 0
   }
})
const ubErrWidth = computed(() => {
   return fullWidth - lbErrWidth.value
})
</script>
<style lang="scss" scoped>
#stat-sig-renderer {
   position: relative;
   display: flex;
   justify-content: start;
   align-items: center;
}
#lb-error {
   background-color: rgba(255, 0, 0, 0.5);
   height: 1.2em;
}
#ub-error {
   background-color: rgba(0, 255, 0, 0.5);
   height: 1.2em;
}
#lb-number {
   font-size: 0.8em;
   //    font-weight: bold;
   margin-right: 10px;
}
#ub-number {
   font-size: 0.8em;
   //    font-weight: bold;
   margin-left: 10px;
}
</style>
